import Home from '../../views/Home.vue';

export default {
    path: '/order',
    name: 'order',
    component: Home,
    redirect: '/order/orderList',
    meta: {
        name: '订单',
        title: '订单管理',
        rules: 1,
    },
    children: [
        {
            path: '/order/serviceOrderList',
            component: () => import('@/beauty/order/orderList'),
            name: 'beauty-orderList',
            meta: {
                name: '订单列表',
                rules: 1,
            },
        },
        {
            path: '/order/serviceOrderInfo',
            component: () => import('@/beauty/order/orderInfo'),
            name: 'beauty-orderInfo',
            meta: {
                name: '订单详情',
                relation: 'beauty-orderList',
                rules: 0,
            },
        },
        {
            path: '/order/orderList',
            component: () => import('@/views/order/OrderList'),
            name: 'order-orderList',
            meta: {
                name: '订单列表',
                rules: 1,
            },
        },
        {
            path: '/order/salesRefund',
            component: () => import('@/views/order/SalesRefund'),
            name: 'order-salesRefund',
            meta: {
                name: '售后退款',
                rules: 1,
            },
        },
        {
            path: '/order/salesHandle',
            component: () => import('@/views/order/SalesHandle'),
            name: 'order-salesHandle',
            meta: {
                name: '售后处理',
                relation: 'order-salesRefund',
                rules: 0,
            },
        },

        {
            path: '/order/orderInfo',
            component: () => import('@/views/order/OrderInfo'),
            name: 'order-orderInfo',
            meta: {
                name: '订单信息',
                relation: 'order-orderList',
                rules: 0,
            },
        },
        {
            path: '/order/cashierOrder',
            name: 'order-cashierOrder',
            component: () => import('@/views/order/cashierOrder'),
            meta: {
                name: '收银台订单',
                rules: 1,
            },
        },
        {
            path: '/order/recyclingOrder',
            name: 'order-recyclingOrder',
            component: () => import('@/O2O/order/recyclingOrder'),
            meta: {
                name: '回收订单',
                rules: 1,
            },
        },
        {
            path: '/order/orderDetail',
            component: () => import('@/O2O/order/orderDetail'),
            name: 'order-recyclingOrderDetail',
            meta: {
                name: '订单详情',
                relation: 'order-recyclingOrder',
                rules: 0,
            },
        },
        {
            path: '/order/invoicingCenter',
            component: () => import('@/views/order/invoicingCenter'),
            name: 'order-invoicingCenter',
            meta: {
                name: '开票中心',
                rules: 1,
            },
        },
        {
            path: '/order/gatewayGoodsOrder',
            component: () => import('@/gateway/order/OrderList'),
            name: 'order-gatewayGoodsOrder',
            meta: {
                name: '商品订单',
                rules: 1,
            },
        },
        {
            path: '/order/gatewayGoodsOrderInfo',
            component: () => import('@/gateway/order/OrderInfo'),
            name: 'order-gatewayGoodsOrderInfo',
            meta: {
                name: '订单详情',
                relation: 'order-gatewayGoodsOrder',
                rules: 0,
            },
        },
        {
            path: '/order/gatewayServiceOrder',
            component: () => import('@/gateway/order/ServiceOrderList'),
            name: 'order-gatewayServiceOrder',
            meta: {
                name: '服务订单',
                rules: 1,
            },
        },
        {
            path: '/order/gatewayServiceOrderInfo',
            component: () => import('@/gateway/order/ServiceOrderInfo'),
            name: 'order-gatewayServiceOrderInfo',
            meta: {
                name: '订单详情',
                relation: 'order-gatewayServiceOrder',
                rules: 0,
            },
        },
        {
            path: '/order/gatewaySalesRefund',
            component: () => import('@/gateway/order/SalesRefund'),
            name: 'order-gatewaySalesRefund',
            meta: {
                name: '售后列表',
                rules: 1,
            },
        },
        {
            path: '/order/gatewaySalesHandle',
            component: () => import('@/gateway/order/SalesHandle'),
            name: 'order-gatewaySalesHandle',
            meta: {
                name: '售后详情',
                relation: 'order-gatewaySalesRefund',
                rules: 0,
            },
        },
    ],
}