import Home from '../../views/Home.vue';
export default {
    path: '/hardware',
    name: 'hardware',
    component: Home,
    redirect: '/hardware/printTicket',
    meta: {
        name: '硬件',
        title: '硬件管理',
        rules: 1,
    },
    children: [
        {
            path: '/hardware/printTicket',
            name: 'hardware-printTicket',
            component: () => import('@/views/hardware/printTicket'),
            meta: {
                name: '打印机设置',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/hardware/scanCodeGun',
            name: 'hardware-scanCodeGun',
            component: () => import('@/views/hardware/scanCodeGun'),
            meta: {
                name: '扫描枪',
                rules: 1,
            },
        },
        {
            path: '/hardware/Pad',
            name: 'hardware-pad',
            component: () => import('@/views/hardware/Pad'),
            meta: {
                name: 'PAD',
                rules: 1,
            },
        },
    ],
}