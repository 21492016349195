import Home from '../../views/Home.vue';
export default {
  path: '/marketing',
  name: 'marketing',
  component: Home,
  redirect: '/marketing/repairCouponList',
  meta: {
    name: '营销',
    title: '营销管理',
    rules: 1,
  },
  children: [
    // 都能修
    {
      path: '/marketing/repairCouponList',
      name: 'marketing-repairCouponList',
      component: () => import('@/repair/marketing/couponList'),
      meta: {
        name: '优惠券列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairAddCoupon',
      name: 'marketing-repairAddSettled',
      component: () => import('@/repair/marketing/addCoupon'),
      meta: {
        name: '添加优惠券',
        relation: 'marketing-repairCouponList',
        rules: 0,
      },
    },
    {
      path: '/marketing/repairVIPSetting',
      name: 'marketing-repairVIPSetting',
      component: () => import('@/repair/marketing/VIPSetting'),
      meta: {
        name: '会员设置',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairCouponDetail',
      name: 'marketing-repairCouponDetail',
      component: () => import('@/repair/marketing/couponDetail'),
      meta: {
        name: '优惠券明细',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairScoreSetting',
      name: 'marketing-repairScoreSetting',
      component: () => import('@/repair/marketing/scoreSetting'),
      meta: {
        name: '积分设置',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairScoreDetail',
      name: 'marketing-repairScoreDetail',
      component: () => import('@/repair/marketing/scoreDetail'),
      meta: {
        name: '积分明细',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairAnnouncementList',
      name: 'marketing-repairAnnouncementList',
      component: () => import('@/repair/marketing/announcementList'),
      meta: {
        name: '公告列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairAddAnnouncement',
      name: 'marketing-repairAddAnnouncement',
      component: () => import('@/repair/marketing/addAnnouncement'),
      meta: {
        name: '添加公告',
        relation: 'marketing-repairAnnouncementList',
        rules: 0,
      },
    },
    {
      path: '/marketing/repairEditAnnouncement',
      name: 'marketing-repairEditAnnouncement',
      component: () => import('@/repair/marketing/addAnnouncement'),
      meta: {
        name: '编辑公告',
        relation: 'marketing-repairAnnouncementList',
        rules: 0,
      },
    },
    {
      path: '/marketing/repairAnnouncementClass',
      name: 'marketing-repairAnnouncementClass',
      component: () => import('@/repair/marketing/announcementClass'),
      meta: {
        name: '公告分类',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairMarketingBasicSet',
      name: 'marketing-repairMarketingBasicSet',
      component: () => import('@/repair/marketing/marketingBasicSet'),
      meta: {
        name: '基础设置',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairAdvertisingReview',
      name: 'marketing-repairAdvertisingReview',
      component: () => import('@/repair/marketing/advertisingReview'),
      meta: {
        name: '广告审核',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairAdvertisingList',
      name: 'marketing-repairAdvertisingList',
      component: () => import('@/repair/marketing/advertisingList'),
      meta: {
        name: '广告列表',
        rules: 1,
      },
    },
    //{
    //  path: '/marketing/repairAdvertisingAdd',
    //  name: 'marketing-repairAdvertisingAdd',
    //  component: () => import('@/repair/marketing/advertisingForm'),
    //  meta: {
    //    name: '添加广告',
    //    relation: 'marketing-repairAdvertisingList',
    //    rules: 0,
    //    form: 0,
    //  },
    //},
    {
      path: '/marketing/repairAdvertisingEdit',
      name: 'marketing-repairAdvertisingEdit',
      component: () => import('@/repair/marketing/advertisingForm'),
      meta: {
        name: '编辑广告',
        relation: 'marketing-repairAdvertisingList',
        rules: 0,
        form: 0,
      },
    },
    {
      path: '/marketing/repairActivityList',
      name: 'marketing-repairActivityList',
      component: () => import('@/repair/marketing/activityList'),
      meta: {
        name: '活动列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairActivityAdd',
      name: 'marketing-repairActivityAdd',
      component: () => import('@/repair/marketing/activityForm'),
      meta: {
        name: '添加活动',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/marketing/repairActivityEdit',
      name: 'marketing-repairActivityEdit',
      component: () => import('@/repair/marketing/activityForm'),
      meta: {
        name: '编辑活动',
        relation: 'marketing-repairActivityList',
        rules: 0,
        form: 0,
      },
    },
    {
      path: '/marketing/repairActivityClassifyList',
      name: 'marketing-repairActivityClassifyList',
      component: () => import('@/repair/marketing/activityClassifyList'),
      meta: {
        name: '活动分类',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairActivityAudit',
      name: 'marketing-repairActivityAudit',
      component: () => import('@/repair/marketing/activityAudit'),
      meta: {
        name: '活动审核',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairActivityJoin',
      name: 'marketing-repairActivityJoin',
      component: () => import('@/repair/marketing/activityJoin'),
      meta: {
        name: '参与列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairPurchaseList',
      name: 'marketing-repairPurchaseList',
      component: () => import('@/repair/marketing/purchaseList'),
      meta: {
        name: '采购列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairPurchaseAdd',
      name: 'marketing-repairPurchaseAdd',
      component: () => import('@/repair/marketing/purchaseForm'),
      meta: {
        name: '添加采购',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/marketing/repairPurchaseEdit',
      name: 'marketing-repairPurchaseEdit',
      component: () => import('@/repair/marketing/purchaseForm'),
      meta: {
        name: '编辑采购',
        relation: 'marketing-repairPurchaseList',
        rules: 0,
        form: 0,
      },
    },
    {
      path: '/marketing/repairCardList',
      name: 'marketing-repairCardList',
      component: () => import('@/repair/marketing/cardList'),
      meta: {
        name: '消费卡列表',
        rules: 1,
      },
    },
    {
      path: '/marketing/repairCardAdd',
      name: 'marketing-repairCardAdd',
      component: () => import('@/repair/marketing/cardAdd'),
      meta: {
        name: '添加消费卡',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/marketing/repairCardDetail',
      name: 'marketing-repairCardDetail',
      component: () => import('@/repair/marketing/cardDetail'),
      meta: {
        name: '消费卡明细',
        rules: 1,
      },
    },
  ],
};
