import Home from '../../views/Home.vue';
export default {
  path: '/goods',
  name: 'goods',
  component: Home,
  redirect: '/goods/goodsList',
  meta: {
    name: '商品',
    title: '商品管理',
    rules: 1,
  },
  children: [
    {
      path: '/goods/goodsList',
      component: () => import('@/views/goods/GoodsList'),
      name: 'goods-goodsList',
      meta: {
        name: '商品列表',
        rules: 1,
      },
    },
    {
      path: '/goods/addGoods',
      component: () => import('@/views/goods/AddGoods'),
      name: 'goods-addGoods',
      meta: {
        name: '添加商品',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/goods/editGoods',
      component: () => import('@/views/goods/AddGoods'),
      name: 'goods-editGoods',
      meta: {
        name: '编辑商品',
        relation: 'goods-addGoods',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/goods/editGoodsType',
      component: () => import('@/views/goods/AddType'),
      name: 'goods-editGoodsType',
      meta: {
        name: '编辑分类',
        relation: 'goods-addGoods',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/goods/GoodsType',
      component: () => import('@/views/goods/GoodsType'),
      name: 'goods-goodsType',
      meta: {
        name: '商品分类',
        rules: 1,
      },
    },
    {
      path: '/goods/AddType',
      component: () => import('@/views/goods/AddType'),
      name: 'goods-addType',
      meta: {
        name: '添加分类',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/goods/GoodsEvaluate',
      component: () => import('@/views/goods/GoodsEvaluate'),
      name: 'goods-GoodsEvaluate',
      meta: {
        name: '商品评价',
        rules: 1,
      },
    },
    //Gateway
    {
      path: '/gateway/goods/goodsList',
      component: () => import('@/gateway/goods/goodsList'),
      name: 'beauty-goods-goodsList',
      meta: {
        name: '商品列表',
        rules: 1,
      },
    },
    {
      path: '/gateway/goods/addGoods',
      component: () => import('@/gateway/goods/addGoods'),
      name: 'beauty-goods-addGoods',
      meta: {
        name: '添加商品',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/gateway/goods/goodsType',
      component: () => import('@/gateway/goods/goodsType'),
      name: 'beauty-goods-goodsType',
      meta: {
        name: '商品分类',
        rules: 1,
      },
    },
    {
      path: '/gateway/goods/addType',
      component: () => import('@/gateway/goods/addType'),
      name: 'beauty-goods-addType',
      meta: {
        name: '添加分类',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/gateway/goods/editGoods',
      component: () => import('@/gateway/goods/addGoods'),
      name: 'gateway-goods-editGoods',
      meta: {
        name: '编辑商品',
        relation: 'beauty-goods-goodsList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/gateway/goods/editGoodsType',
      component: () => import('@/gateway/goods/addType'),
      name: 'gateway-goods-editGoodsType',
      meta: {
        name: '编辑分类',
        relation: 'beauty-goods-goodsType',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/gateway/goods/goodsEvaluate',
      component: () => import('@/gateway/goods/goodsEvaluate'),
      name: 'gateway-goods-goodsEvaluate',
      meta: {
        name: '商品评价',
        rules: 1,
      },
    },
    //repair
    {
      path: '/repair/goods/list',
      component: () => import('@/repair/goods/list'),
      name: 'repair-goods-list',
      meta: {
        name: '商品列表',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/add',
      component: () => import('@/repair/goods/form'),
      name: 'repair-goods-add',
      meta: {
        name: '添加商品',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/repair/goods/edit',
      component: () => import('@/repair/goods/form'),
      name: 'repair-goods-edit',
      meta: {
        name: '编辑商品',
        relation: 'repair-goods-list',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/repair/goods/typelist',
      component: () => import('@/repair/goods/typelist'),
      name: 'repair-goods-typelist',
      meta: {
        name: '商品分类',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/addType',
      component: () => import('@/repair/goods/typeform'),
      name: 'repair-goods-addType',
      meta: {
        name: '添加分类',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/repair/goods/editType',
      component: () => import('@/repair/goods/typeform'),
      name: 'repair-goods-typeform',
      meta: {
        name: '编辑分类',
        relation: 'repair-goods-typelist',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/repair/goods/orderlist',
      component: () => import('@/repair/goods/orderlist'),
      name: 'repair-goods-orderlist',
      meta: {
        name: '商品订单',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/orderinfo',
      component: () => import('@/repair/goods/orderinfo'),
      name: 'repair-goods-orderlist',
      meta: {
        name: '订单详情',
        relation: 'repair-goods-orderlist',
        rules: 0,
      },
    },
    {
      path: '/repair/goods/salelist',
      component: () => import('@/repair/goods/salelist'),
      name: 'repair-goods-salelist',
      meta: {
        name: '商品售后',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/saleinfo',
      component: () => import('@/repair/goods/saleinfo'),
      name: 'repair-goods-saleinfo',
      meta: {
        name: '售后退款',
        relation: 'repair-goods-salelist',
        rules: 0,
      },
    },
    {
      path: '/repair/goods/evaluate',
      component: () => import('@/repair/goods/evaluate'),
      name: 'repair-goods-evaluate',
      meta: {
        name: '商品评价',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/invoicingCenter',
      component: () => import('@/repair/goods/invoicingCenter'),
      name: 'repair-goods-invoicingCenter',
      meta: {
        name: '开票中心',
        rules: 1,
      },
    },
    {
      path: '/repair/goods/payInStore',
      component: () => import('@/repair/goods/payInStore'),
      name: 'repair-goods-payInStore',
      meta: {
        name: '到店支付',
        rules: 1,
      },
    },
  ],
};
