import axios from '../../util/axios';
import api from '../../util/api';
const default_state = {
  logisticsList: [],
  cancelList: [],
  orderInfo: {},
};
const state = {
  logisticsList: [],
  cancelList: [],
  orderInfo: {},
};
const mutations = {
  resetState(state) {
    let data = Object.assign({}, default_state);
    for (let i in data) {
      state[i] = data[i];
    }
  },
  getLogistics(state, data) {
    state.logisticsList = data;
  },
  getCancelList(state, data) {
    state.cancelList = data;
  },
  getOrderInfo(state, data) {
    state.orderInfo = data;
  },
};

const actions = {
  getLogistics({ commit }, data = {}) {
    axios.post(api.order.express, data).then(res => {
      if (res.code == 0) {
        commit('getLogistics', res.result.list);
      }
    });
  },
  getCancelList({ commit }, data = {}) {
    axios.post(api.order.cancelList, data).then(res => {
      if (res.code == 0) {
        commit('getCancelList', res.result.list);
      }
    });
  },
  getOrderInfo({ commit }, data = {}) {
    axios.post(data.industryName + api.order.info, { id: data.id }).then(res => {
      if (res.code == 0) {
        res.result.info.extend_order_extend.reciver_infos.combine_detail = res.result.info.extend_order_extend.reciver_infos.combine_detail.replace(/\s*/g, '');
        commit('getOrderInfo', res.result);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
