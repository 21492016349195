import Home from '../../views/Home.vue';

export default {
    path: '/user',
    name: 'user',
    component: Home,
    redirect: '/user/userList',
    meta: {
        name: '用户',
        title: '用户管理',
        rules: 1,
    },
    children: [
        {
            path: '/user/equityMember',
            name: 'user-equityMember',
            component: () => import('@/views/user/EquityMember'),
            meta: {
                name: '权益会员',
                rules: 1,
            },
        },
        {
            path: '/user/equityMemberInfo',
            name: 'user-equityMemberInfo',
            component: () => import('@/views/user/EquityMemberInfo'),
            meta: {
                name: '用户详情',
                relation: 'user-equityMember',
                rules: 0,
            },
        },
        {
            path: '/user/userList',
            name: 'user-userList',
            component: () => import('@/views/user/UserList'),
            meta: {
                name: '用户列表',
                rules: 1,
            },
        },
        {
            path: '/user/userLog',
            name: 'user-userLog',
            component: () => import('@/views/user/UserLog'),
            meta: {
                name: '用户日志',
                rules: 1,
            },
        },
        {
            path: '/user/userPortrait',
            name: 'user-userPortrait',
            component: () => import('@/views/user/UserPortrait'),
            meta: {
                name: '用户画像',
                rules: 1,
            },
        },
        {
            path: '/user/card',
            name: 'user-card',
            component: () => import('@/views/user/Card'),
            meta: {
                name: '会员卡',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/user/userDetail',
            name: 'user-userDetail',
            component: () => import('@/views/user/UserDetail'),
            meta: {
                name: '用户详情',
                relation: 'user-userList',
                rules: 0,
            },
        },
        {
            path: '/user/userGroup',
            name: 'user-userGroup',
            component: () => import('@/views/user/UserGroup'),
            meta: {
                name: '用户分群',
                rules: 1,
            },
        },
        {
            path: '/user/addGroup',
            name: 'user-addGroup',
            component: () => import('@/views/user/AddGroup'),
            meta: {
                name: '新建分群',
                relation: 'user-userGroup',
                rules: 0,
                form: 1,
            },
        },
        // {
        //   path:'/user/crowdOperate',
        //   name:'crowdOperate',
        //   component:()=> import('@/views/user/CrowdOperate'),
        //   meta:{
        //     name:'人群运营',
        //     rules:1
        //   }
        // }
        {
            path: '/user/enterpriseWechat',
            name: 'user-enterpriseWechat',
            redirect: '/user/enterpriseWechat/companyWechart',
            component: () => import('@/views/user/enterpriseWechat/index'),
            meta: {
                name: '企微助手',
                rules: 1,
                isOpenThirdMenu: 0,
                isShowThirdMemu: 1,
            },
            children: [
                {
                    path: '/user/enterpriseWechat/companyWechart',
                    name: 'user-enterpriseWechat-companyWechart',
                    component: () => import('@/views/user/enterpriseWechat/companyWechart'),
                    meta: {
                        name: '企微授权',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/userList',
                    name: 'user-enterpriseWechat-userList',
                    component: () => import('@/views/user/enterpriseWechat/userList'),
                    meta: {
                        name: '用户管理',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/productAtlas',
                    name: 'user-enterpriseWechat-productAtlas',
                    component: () => import('@/views/user/enterpriseWechat/productAtlas'),
                    meta: {
                        name: '产品图册',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/sendLog',
                    name: 'user-enterpriseWechat-sendLog',
                    component: () => import('@/views/user/enterpriseWechat/sendLog'),
                    meta: {
                        name: '发送记录',
                        rules: 0,
                        relation: 'user-enterpriseWechat-userList',
                    },
                },
                {
                    path: '/user/enterpriseWechat/staff',
                    name: 'user-enterpriseWechat-staff',
                    component: () => import('@/views/user/enterpriseWechat/staff'),
                    meta: {
                        name: '员工管理',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/staffDetail',
                    name: 'user-enterpriseWechat-staffDetail',
                    component: () => import('@/views/user/enterpriseWechat/staffDetail'),
                    meta: {
                        name: '员工客户',
                        rules: 0,
                        relation: 'user-enterpriseWechat-staff',
                    },
                },
                {
                    path: '/user/enterpriseWechat/messageGroup',
                    name: 'user-enterpriseWechat-messageGroup',
                    component: () => import('@/views/user/enterpriseWechat/messageGroup'),
                    meta: {
                        name: '群发到群',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/messageTeam/add',
                    name: 'user-enterpriseWechat-messageTeam-add',
                    component: () => import('@/views/user/enterpriseWechat/messageGroupAdd'),
                    meta: {
                        name: '添加群发到群消息',
                        relation: 'user-enterpriseWechat-messageGroup',
                        rules: 0,
                        form: 1,
                    },
                },
                {
                    path: '/user/messageTeam/messageDetail',
                    name: 'user-enterpriseWechat-messageDetail',
                    component: () => import('@/views/user/enterpriseWechat/messageDetail'),
                    meta: {
                        name: '消息详情',
                        relation: 'user-enterpriseWechat-messageGroup',
                        rules: 0,
                        form: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/messagePerson',
                    name: 'user-enterpriseWechat-messagePerson',
                    component: () => import('@/views/user/enterpriseWechat/messageGroup'),
                    meta: {
                        name: '群发到客户',
                        rules: 1,
                        selected: 0,
                        form: 1,
                    },
                },
                {
                    path: '/user/messagePerson/add',
                    name: 'user-enterpriseWechat-messageTeam-add',
                    component: () => import('@/views/user/enterpriseWechat/messageGroupAdd'),
                    meta: {
                        name: '添加群发到客户消息',
                        relation: 'user-enterpriseWechat-messagePerson',
                        rules: 0,
                        form: 1,
                    },
                },
                {
                    path: '/user/messagePerson/messageDetail',
                    name: 'user-enterpriseWechatmessageDetail',
                    component: () => import('@/views/user/enterpriseWechat/messageDetail'),
                    meta: {
                        name: '消息详情',
                        relation: 'user-enterpriseWechat-messagePerson',
                        rules: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/friendWelcome',
                    name: 'user-enterpriseWechat-friendWelcome',
                    component: () => import('@/views/user/enterpriseWechat/friendWelcome'),
                    meta: {
                        name: '好友欢迎语',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/teamManage',
                    name: 'user-enterpriseWechat-teamManage',
                    component: () => import('@/views/user/enterpriseWechat/teamManage'),
                    meta: {
                        name: '群管理',
                        rules: 1,
                        selected: 0,
                    },
                },
                {
                    path: '/user/enterpriseWechat/teamManageDetail',
                    name: 'user-enterpriseWechat-teamManageDetail',
                    component: () => import('@/views/user/enterpriseWechat/teamManageDetail'),
                    meta: {
                        name: '群详情',
                        rules: 0,
                        relation: 'user-enterpriseWechat-teamManage',
                    },
                },
            ],
        },
        {
            path: '/user/O2OuserList',
            name: 'user-O2OuserList',
            component: () => import('@/O2O/user/userList'),
            meta: {
                name: '用户列表',
                rules: 1,
            },
        },
        {
            path: '/user/O2OuserDetail',
            name: 'user-userDetail',
            component: () => import('@/O2O/user/userDetail'),
            meta: {
                name: '用户详情',
                rules: 0,
                relation: 'user-O2OuserList',
            },
        },
        {
            path: '/user/recyclerList',
            name: 'user-recyclerList',
            component: () => import('@/O2O/user/recyclerList'),
            meta: {
                name: '回收员列表',
                rules: 1,
            },
        },
        {
            path: '/user/addRecycler',
            name: 'user-addRecycler',
            component: () => import('@/O2O/user/addRecycler'),
            meta: {
                name: '添加回收员',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/user/editRecycler',
            name: 'user-editRecycler',
            component: () => import('@/O2O/user/addRecycler'),
            meta: {
                name: '编辑回收员',
                rules: 0,
                form: 1,
                relation: 'user-addRecycler',
            },
        },
        //   美业
        {
            path: '/user/beautyUserList',
            name: 'user-beautyUserList',
            component: () => import('@/beauty/user/userList'),
            meta: {
                name: '用户列表',
                rules: 1,
            },
        },
        {
            path: '/user/beautyUserDetail',
            name: 'user-beautyUserDetail',
            component: () => import('@/beauty/user/userDetail'),
            meta: {
                name: '用户详情',
                relation: 'user-beautyUserList',
                rules: 0,
            },
        },
        {
            path: '/user/beautyEmployeeList',
            name: 'user-beautyEmployeeList',
            component: () => import('@/beauty/user/employeeList'),
            meta: {
                name: '员工列表',
                rules: 1,
            },
        },
        {
            path: '/user/beautyEmployeeSchedule',
            name: 'user-beautyEmployeeSchedule',
            component: () => import('@/beauty/user/employeeSchedule'),
            meta: {
                name: '员工排班',
                rules: 1,
            },
        },
        // 都能修
        {
            path: '/user/repairUserList',
            name: 'user-repairUserList',
            component: () => import('@/repair/user/userList'),
            meta: {
                name: '会员列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairUserDetail',
            name: 'user-repairUserDetail',
            component: () => import('@/repair/user/userDetail'),
            meta: {
                name: '会员详情',
                relation: 'user-repairUserList',
                rules: 0,
            },
        },
        {
            path: '/user/repairSettledAuditing',
            name: 'user-repairSettledAuditing',
            component: () => import('@/repair/user/settledAuditing'),
            meta: {
                name: '技工审核',
                rules: 1,
            },
        },
        {
            path: '/user/repairSettledList',
            name: 'user-repairSettledList',
            component: () => import('@/repair/user/settledList'),
            meta: {
                name: '技工列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairSettledDetail',
            name: 'user-repairSettledDetail',
            component: () => import('@/repair/user/settledDetail'),
            meta: {
                name: '技工详情',
                relation: 'user-repairSettledList',
                rules: 0,
            },
        },
        {
            path: '/user/repairAddSettled',
            name: 'user-repairAddSettled',
            component: () => import('@/repair/user/addSettled'),
            meta: {
                name: '添加技工',
                relation: 'user-repairSettledList',
                rules: 0,
            },
        },
        {
            path: '/user/repairBondOrder',
            name: 'user-repairBondOrder',
            component: () => import('@/repair/user/bondOrder'),
            meta: {
                name: '保证金订单',
                rules: 1,
            },
        },
        {
            path: '/user/repairPartnerReview',
            name: 'user-repairPartnerReview',
            component: () => import('@/repair/user/partnerReview'),
            meta: {
                name: '合伙人审核',
                rules: 1,
            },
        },
        {
            path: '/user/repairPartnerList',
            name: 'user-repairPartnerList',
            component: () => import('@/repair/user/partnerList'),
            meta: {
                name: '合伙人列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairVillageList',
            name: 'user-repairVillageList',
            component: () => import('@/repair/user/villageList'),
            meta: {
                name: '小区列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairSubsidiaryList',
            name: 'user-repairSubsidiaryList',
            component: () => import('@/repair/user/subsidiaryList'),
            meta: {
                name: '子公司列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairPropertyList',
            name: 'user-repairPropertyList',
            component: () => import('@/repair/user/propertyList'),
            meta: {
                name: '物业列表',
                rules: 1,
            },
        },
        {
            path: '/user/repairStoreAuditList',
            name: 'user-repairStoreAuditList',
            component: () => import('@/repair/user/storeAuditList'),
            meta: {
                name: '商家审核',
                rules: 1,
            },
        },
        {
            path: '/user/repairStoreList',
            name: 'user-repairStoreList',
            component: () => import('@/repair/user/storeList'),
            meta: {
                name: '商家列表',
                rules: 1,
            },
        },
    ],
}
