<template>
  <div class="content">
    <div class="shop-header">
      <div class="shop-header-content">
        <img src="../assets/logoH80.png" />
        <div class="outLogin">
          <span>{{ userInfo.u_account }}</span>
          <span @click="outLogin">退出</span>
        </div>
      </div>
    </div>
    <div class="view-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
  },
  methods: {
    outLogin() {
      this.$store.dispatch('user/outLogin');
    },
  },
};
</script>

<style lang="less" scoped>
.shop-header {
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  .shop-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 950px;
    .outLogin {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      span:last-child {
        color: #1890ff;
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
}
.view-content{
    padding:20px 150px;
}
</style>
