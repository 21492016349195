<template>
    <div class="noticeTabsBox">
        <div class="tabs" ref="tabs">
            <div class="tabsItem" v-for="(item, index) in tabsList" :key="index" @click="changeTabs(index)"
                :style="{ color: activeIndex == index ? '#409eff' : '' }">
                {{ item.title }}({{ item.unread_count }})
            </div>
        </div>
        <div class="activeLine">
            <div class="active" :style="activeStyle"></div>
        </div>
        <div class="contentBox" v-if="isLoading"></div>
        <div class="contentBox" v-else @scroll="pageList">
            <No v-if="showList.length == 0"></No>
            <div class="contentItem" v-for="(item, index) in showList" :key="index">
                <div class="red" v-if="!item.is_read && activeIndex == 2"></div>
                <div class="red" v-if="!item.read_state && activeIndex !== 2"></div>
                <div v-if="activeIndex == 2" :style="{ marginLeft: item.is_read ? '15px' : '' }" class="title">
                    {{ item.content }}
                </div>
                <div v-else :style="{ marginLeft: item.read_state ? '15px' : '' }" class="title">
                    {{ item.title }}
                </div>
                <div class="time">{{ getDateformat(item.create_time) }}</div>
                <div class="btn" @click="dealFn(item, index)">
                    <div>{{ activeIndex === 2 ? '去处理' : '查看内容' }}</div>
                </div>
            </div>
        </div>
        <div class="btnBox">
            <div @click="allRead">全部已读</div>
            <div @click="lookMore">查看更多</div>
        </div>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate'
export default {
    data () {
        return {
            tabsList: [
                { title: '公告通知', unread_count: 0 },
                { title: '系统升级', unread_count: 0 },
                { title: '消息通知', unread_count: 0 }
            ],
            activeStyle: { transform: 'translateX(0px)' },
            activeIndex: 0,
            showList: [],
            getDateformat,
            page: 1,
            rows: 10,
            total: 0,
            isLoading: false
        }
    },
    methods: {
        showFirst () {
            let type = this.activeIndex + 1
            this.getTypeList()
            this.getMsgList(type)
        },
        changeTabs (index) {
            this.activeIndex = index
            let basicMove = this.$refs.tabs.clientWidth / 3
            this.activeStyle.transform = `translateX(${index * basicMove}px)`
            this.getMsgList(index + 1)
            this.$emit('removeMsg')
        },
        lookMore () {
            if (this.$route.path !== '/noticeList') {
                this.$router.push('/notice')
            }
        },
        getMsgList (type, isAddPage) {
            if (!isAddPage) {
                this.page = 1
                this.isLoading = true
            }
            this.$axios
                .post(this.$api.homeIndex.tabsMessage, {
                    page: this.page,
                    rows: this.rows,
                    type_id: type
                })
                .then((res) => {
                    if (res.code === 0) {
                        if (isAddPage) {
                            this.showList.push(...res.result.list)
                        } else {
                            this.showList = res.result.list
                            this.isLoading = false
                        }
                        this.total = res.result.total_number
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getTypeList () {
            this.$axios.post(this.$api.homeIndex.typeList).then((res) => {
                if (res.code === 0) {
                    this.tabsList = res.result.list
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        dealFn (item, index) {
            let type = this.activeIndex + 1
            if (this.activeIndex === 2 && !item.is_read) {
                this.$axios.post(this.$api.homeIndex.readMsg, {
                    message_id: item.id,
                    message_type: type
                })
                this.tabsList[this.activeIndex].unread_count--
                this.$emit('removeMsg')
                item.is_read = 1
            }
            if (this.activeIndex !== 2 && !item.read_state) {
                this.$axios.post(this.$api.homeIndex.readMsg, {
                    message_id: item.id,
                    message_type: type
                })
                this.tabsList[this.activeIndex].unread_count--
                this.$emit('removeMsg')
                item.read_state = 1
            }
            if (this.activeIndex === 2) {
                this.toDealMsg(item, index)
            } else {
                if (this.$route.path === '/noticeDetail') {
                    let id = this.$route.query.id
                    if (id == item.id) return
                }
                this.$router.push({
                    path: '/noticeDetail',
                    query: item
                })
            }
        },
        toDealMsg (item, index) {
            if (item.type == 6) {
                this.$message({
                    message: '暂未开放客服模块',
                    type: 'warning'
                })
                return
            }
            let path = ''
            let that = this
            let case_id = localStorage.getItem('case_id')
            switch (item.type) {
                case 1:
                    path = case_id == 15 ? '/repair/goods/orderlist?tab_type=state_pay' : '/order/orderList?tab_type=state_pay';
                    break
                case 2:
                    path = case_id == 15 ? '/repair/goods/salelist?type=1' : '/order/salesRefund';
                    break
                case 3:
                    path = `/store/storeManagement?id=${item.store_id}`
                    break
                case 4:
                    path = `/store/reflectAudit?id=${item.store_id}`
                    break
                case 5:
                    path = '/set/weixin?from=1'
                    break
                case 6:
                    path = ''
                    break
                case 7:
                    path = case_id == 15 ? '/repair/goods/evaluate' : '/goods/GoodsEvaluate';
                    break
                case 8:
                    path = '/user/userList'
                    break
                case 9:
                    path = '/user/userList'
                    break
                case 10:
                    path = '/user/userList'
                    break
                case 50:
                    path = '/user/repairSettledAuditing';
                    break;
                case 51:
                    path = '/user/repairSettledList';
                    break;
                case 52:
                    path = '/user/repairSettledList';
                    break;
                case 53:
                    path = '/user/repairPartnerReview';
                    break;
                case 54:
                    path = '/user/repairStoreAuditList';
                    break;
                case 55:
                    path = '/repair/goods/salelist';
                    break;
                case 56:
                    path = '/repair/finance/depositRefund';
                    break;
                case 57:
                    path = '/repair/finance/artisanWithdraw';
                    break;
                case 58:
                    path = '/repair/finance/storeWithdraw';
                    break;
                case 59:
                    path = '/repair/finance/accounts';
                    break;
                case 60:
                    path = '/marketing/repairAdvertisingReview';
                    break;
                default:
                    break
            }
            if (this.$route.fullPath !== path) {
                that.$router.push(path)
            }
        },
        pageList ($event) {
            const { scrollTop, clientHeight, scrollHeight } = $event.target
            if (scrollTop + clientHeight === scrollHeight) {
                if (this.page * this.rows <= this.total) {
                    this.page++
                    let id = this.activeIndex + 1
                    this.getMsgList(id, 1)
                }
            }
        },
        allRead () {
            if (this.tabsList[this.activeIndex].unread_count === 0) return
            let that = this
            let type = this.activeIndex + 1
            this.$axios
                .post(this.$api.homeIndex.readMsg, {
                    message_id: 'all',
                    message_type: type
                })
                .then((res) => {
                    if (res.code == 0) {
                        that.$message.success('处理成功')
                        that.$emit('removeMsg')
                        that.tabsList[this.activeIndex].unread_count = 0
                        that.getMsgList(type)
                    } else {
                        that.$message.error('处理失败')
                    }
                })
        }
    }
}
</script>

<style lang="less" scoped>
.noticeTabsBox {
    .tabs {
        display: flex;
        font-size: 12px;

        .tabsItem {
            flex: 1;
            text-align: center;
            padding-bottom: 10px;
            cursor: pointer;
        }
    }

    .activeLine {
        height: 1px;
        background-color: #e4e4e4;

        .active {
            width: 33%;
            height: 1px;
            background-color: #409eff;
            transition: all 0.2s;
        }
    }

    .contentBox {
        height: 250px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        .contentItem {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .red {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: red;
                margin-right: 5px;
            }

            .title {
                width: 220px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 20px;
            }

            .btn {
                border: 1px solid #409eff;
                color: #409eff;
                border-radius: 4px;
                font-size: 12px;
                padding: 3px;
                margin-right: 2px;
                cursor: pointer;

                div {
                    transform: scale(0.8);
                }
            }
        }

        .contentItem:last-child {
            margin-bottom: 20px;
        }
    }

    .btnBox {
        height: 30px;
        display: flex;
        border-top: 1px solid #e4e4e4;

        &>div {
            flex: 1;
            text-align: center;
            line-height: 42px;
            cursor: pointer;

            &:hover {
                color: #409eff;
            }
        }
    }
}
</style>
