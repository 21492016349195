<template>
  <div class="laodingBox">
    <div class="outside">
      <div class="mid"></div>
      <div class="inside">
        <img src="../assets/loadingLogo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.laodingBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .outside {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    position: relative;
    .mid {
      width: 68px;
      height: 68px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: url('../assets/quan.png');
      animation: rotate linear 0.5s infinite;
    }
    .inside {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}
</style>
