import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Shop from '../views/Shop.vue';
import SystemManagement from '../views/SystemManagement.vue';
import store from '../store';
import routerSurfaces from './routerSurface.js';
import _ from 'lodash';
import cookies from 'vue-cookies';
Vue.use(VueRouter);

const routes = [
  {
    path: '/notice',
    name: 'notice',
    redirect: '/noticeList',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/noticeList',
        name: 'noticeList',
        component: () => import('@/views/noticeList/index.vue'),
        meta: {
          name: '公告',
          title: '公告列表',
          rules: 1,
        },
      },
      {
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () => import('@/views/noticeList/noticeDetail.vue'),
        meta: {
          name: '公告',
          title: '公告详情',
          rules: 1,
        },
      },
    ],
  },
  {
    path: '/shop',
    name: 'shop',
    redirect: '/shop/index',
    component: Shop,
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/shop/index',
        name: 'shop-index',
        component: () => import('@/views/shop/Index'),
      },
      {
        path: '/shop/employee',
        name: 'shop-employee',
        component: () => import('@/views/shop/Employee'),
      },
      {
        path: '/shop/createShop',
        name: 'shop-createShop',
        component: () => import('@/views/shop/CreateShop'),
      },
      {
        path: '/shop/shopRenew',
        name: 'shop-shopRenew',
        component: () => import('@/views/shop/ShopRenew'),
      },
      {
        path: '/shop/set',
        name: 'shop-set',
        component: () => import('@/views/shop/Set'),
      },
    ],
  },
  //系统管理
  {
    path: '/systemManagement',
    name: 'systemManagement',
    component: SystemManagement,
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/systemManagement/ApplicationManagement',
        name: 'systemManagement-ApplicationManagement',
        component: () => import('@/views/systemManagement/ApplicationManagement'),
      },
      {
        path: '/systemManagement/diyExamine',
        name: 'systemManagement-diyExamine',
        component: () => import('@/views/systemManagement/diyExamine'),
      },
      {
        path: '/systemManagement/AccountManagement',
        name: 'systemManagement-AccountManagement',
        component: () => import('@/views/systemManagement/AccountManagement'),
      },
      {
        path: '/systemManagement/websiteCase',
        name: 'systemManagement-websiteCase',
        component: () => import('@/views/systemManagement/WebsiteCase'),
      },
      {
        path: '/systemManagement/websiteBanner',
        name: 'systemManagement-websiteBanner',
        component: () => import('@/views/systemManagement/WebsiteBanner'),
      },
      {
        path: '/systemManagement/websiteArticle',
        name: 'systemManagement-websiteArticle',
        component: () => import('@/views/systemManagement/WebsiteArticle'),
      },
      {
        path: '/systemManagement/applicationOrder',
        name: 'systemManagement-applicationOrder',
        component: () => import('@/views/systemManagement/ApplicationOrder'),
      },
      {
        path: '/systemManagement/noticeList',
        name: 'systemManagement-noticeList',
        component: () => import('@/views/systemManagement/NoticeList'),
      },
      {
        path: '/systemManagement/helpCenter',
        name: 'systemManagement-helpCenter',
        component: () => import('@/views/systemManagement/HelpCenter'),
      },
      {
        path: '/systemManagement/version',
        name: 'systemManagement-version',
        component: () => import('@/views/systemManagement/Version'),
      },
      {
        path: '/systemManagement/marketingComponent',
        name: 'systemManagement-marketingComponent',
        component: () => import('@/views/systemManagement/MarketingComponent'),
      },
      {
        path: '/systemManagement/formCollection',
        name: 'systemManagement-formCollection',
        component: () => import('@/views/systemManagement/FormCollection'),
      },
      {
        path: '/systemManagement/menu',
        name: 'systemManagement-menu',
        component: () => import('@/views/systemManagement/Menu'),
      },
      {
        path: '/systemManagement/meal',
        name: 'systemManagement-meal',
        component: () => import('@/views/systemManagement/Meal'),
      },
      {
        path: '/systemManagement/appletAuthentication',
        name: 'systemManagement-appletAuthentication',
        component: () => import('@/views/systemManagement/AppletAuthentication'),
      },
      {
        path: '/systemManagement/rechargeLog',
        name: 'systemManagement-rechargeLog',
        component: () => import('@/views/systemManagement/RechargeLog'),
      },
    ],
  },
  // 添加页面
  {
    path: '/addPages',
    name: 'addPages',
    component: () => import('@/views/addPages/index.vue'),
    redirect: '/addPages/customTemplate',
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/addPages/customTemplate',
        name: 'customTemplate',
        meta: {
          name: '自定义模板',
          rules: 1,
        },
        component: () => import('@/views/addPages/pages/customTemplate.vue'),
      },
      {
        path: '/addPages/decorationComponents',
        name: 'decorationComponents',
        meta: {
          name: '店铺装修组件',
          rules: 1,
          form: 1,
        },
        component: () => import('@/views/addPages/pages/decorationComponents.vue'),
      },
      {
        path: '/addPages/bottomMenu',
        name: 'bottomMenu',
        meta: {
          name: '底部菜单',
          rules: 1,
          form: 1,
        },
        component: () => import('@/views/addPages/pages/bottomMenu.vue'),
      },
      {
        path: '/addPages/colorStyle',
        name: 'colorStyle',
        meta: {
          name: '配色风格',
          rules: 1,
          form: 1,
        },
        component: () => import('@/views/addPages/pages/colorStyle.vue'),
      },
    ],
  },
  // 收银台
  {
    path: '/cashier',
    name: 'cashier',
    component: () => import('@/views/hardware/cashier/index.vue'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/hangorder',
    name: 'hangOrder',
    component: () => import('@/views/hardware/cashier/hangOrder.vue'),
    meta: {
      rules: 0,
    },
  },
  //   多版本装修
  {
    path: '/foodSingle', //餐饮单店
    name: 'foodSingle',
    redirect: '/foodSingle/singleindex',
    component: () => import('@/views/multiVersion/layout.vue'),
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/foodSingle/singleindex',
        name: 'foodsingleindex',
        meta: {
          name: '首页装修',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/food/singleindex.vue'),
      },
      {
        path: '/foodSingle/foodbrandstory',
        name: 'foodbrandstory',
        meta: {
          name: '品牌故事',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/food/brandstory.vue'),
      },
    ],
  },
  {
    path: '/foodMulti', //餐饮多店
    name: 'foodMulti',
    redirect: '/foodMulti/multiindex',
    component: () => import('@/views/multiVersion/layout.vue'),
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/foodMulti/multiindex',
        name: 'foodmultiindex',
        meta: {
          name: '首页装修',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/food/multiindex.vue'),
      },
      {
        path: '/foodMulti/foodbrandstory',
        name: 'foodbrandstory',
        meta: {
          name: '品牌故事',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/food/brandstory.vue'),
      },
    ],
  },
  {
    path: '/retailSingle', //零售单店
    name: 'retailSingle',
    redirect: '/retailSingle/singleindex',
    component: () => import('@/views/multiVersion/layout.vue'),
    meta: {
      rules: 0,
    },
    children: [
      {
        path: '/retailSingle/singleindex',
        name: 'retailsingleindex',
        meta: {
          name: '首页装修',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/retail/singleindex.vue'),
      },
      {
        path: '/retailSingle/retailbrandstory',
        name: 'retailbrandstory',
        meta: {
          name: '品牌故事',
          rules: 1,
        },
        component: () => import('@/views/multiVersion/retail/brandstory.vue'),
      },
    ],
  },
  // 美业
  {
    path: '/reserve/cashier',
    name: 'reserve-cashier',
    component: () => import('@/beauty/reserve/cashier'),
    meta: {
      name: '开单收银',
      rules: 1,
    },
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/Print'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      rules: 0,
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/callback'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/wechat_callback',
    name: 'wechat_callback',
    component: () => import('@/views/wechat_callback'),
    meta: {
      rules: 0,
    },
  },
  {
    path: '/404',
    name: 'page404',
    component: () => import('@/views/404'),
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });
const router = createRouter();
let newArray = []; // 存储匹配完成的菜单
let path = []; //所有路由地址
export function setAddRouter() {
  const newRouter = createRouter();
  let menuLeft = store.getters.menuLeft; // 后台返回的菜单列表 （二维数组） -> 三级路由跟routerSurface文件比较后手动添加
  let routerSurface = _.cloneDeep(routerSurfaces); // 深拷贝路由文件
  let arr = []; //
  let relationMenu = [];
  let relationMenuName = [];
  newArray = [];
  path = [];
  //寻找匹配一级菜单
  routerSurface.map(item => {
    menuLeft.map(items => {
      if (item.path == items.path) {
        item.meta.name = items.meta.name;
        item.meta.icon = items.meta.icon;
        arr.push(item);
      }
    });
    //处理首页显示
    menuLeft.map(items => {
      if (item.path == '/' && items.path == 'index') {
        item.children[0].meta.key = 1;
        items.path = '/';
        item.meta.icon = items.meta.icon;
        arr.unshift(item);
      }
    });
  });
  //获取不用显示在菜单的路由
  arr.map(item => {
    item.children.map(items => {
      if (items.meta.rules == 0) {
        relationMenu.push(items);
      }
    });
  });
  //寻找匹配二级菜单 key为1 则有二级菜单
  menuLeft.map(item1 => {
    item1.children.map(item2 => {
      arr.map(item3 => {
        item3.children.map(item4 => {
          if (item2.path == item4.path) {
            item4.meta.key = 1;
            item4.meta.name = item2.meta.name;
          }
        });
      });
    });
  });
  //过滤掉不是二级菜单
  arr.forEach((now, index) => {
    newArray[index] = now;
    newArray[index].children = now.children.filter(item => item.meta.key);
  });
  //添加不显示菜单的路由
  for (let i in newArray) {
    let children = newArray[i].children;
    for (let y in children) {
      for (let k in relationMenu) {
        if (children[y].name == relationMenu[k].meta.relation) {
          children.push(relationMenu[k]);
          relationMenuName.push(relationMenu[k].name);
          path.push(relationMenu[k].path);
        }
      }
      for (let k in children[y].children) {
        relationMenuName.push(children[y].children[k].name);
        path.push(children[y].children[k].path);
      }
    }
  }
  //根据接口返回的菜单顺序进行路由排序
  //一级菜单排序
  newArray.sort((a, b) => {
    let sortPath = [];
    menuLeft.map(item => sortPath.push(item.path));
    return sortPath.indexOf(a.path) - sortPath.indexOf(b.path);
  });
  //二级菜单排序
  for (let i in newArray) {
    let children = newArray[i].children;
    let childMenu = menuLeft[i].children;
    if (children.length) {
      children.sort((a, b) => {
        let sortPath = [];
        childMenu.map(item => sortPath.push(item.path));
        return sortPath.indexOf(a.path) - sortPath.indexOf(b.path);
      });
      if (childMenu.length) newArray[i].redirect = childMenu[0].path;
    }
  }
  //设置不显示的菜单
  store.commit('user/setRelationMenu', relationMenuName);
  router.matcher = newRouter.matcher;
  router.addRoutes(newArray);
  //设置菜单
  store.commit('user/setRoutes', _.cloneDeep(newArray));
}

router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next();
  } else {
    let token = cookies.get('token');
    if (!token) {
      router.push('/login').catch(err => err);
    } else {
      let menuLeft = store.getters.menuLeft; // 处理完成后的所有菜单
      if (menuLeft.length) {
        next();
      } else {
        if (to.path == '/callback') {
          next();
        } else {
          next();
          let pathName = []; //不需要加载菜单的路由地址名称
          for (let i in routes) {
            // routes -> 进入站点前使用的路由 与 站点内左侧菜单无关
            if (routes[i].path != '/notice') {
              pathName.push(routes[i].name);
              for (let y in routes[i].children) {
                pathName.push(routes[i].children[y].name);
              }
            }
          }
          //判断路由地址有没有在站点外面，在外面就不需要请求菜单
          if (!pathName.includes(to.name)) {
            store.dispatch('user/get_menu', {
              success() {
                setAddRouter();
                for (let i in routes) {
                  path.push(routes[i].path);
                  for (let y in routes[i].children) {
                    path.push(routes[i].children[y].path);
                  }
                }
                for (let i in newArray) {
                  path.push(newArray[i].path);
                  for (let y in newArray[i].children) {
                    path.push(newArray[i].children[y].path);
                    for (let k in newArray[i].children[y].children) {
                      path.push(newArray[i].children[y].children[k].path);
                    }
                  }
                }
                //判断返回的菜单有没有首页并且当前跳转的路由是不是首页
                if (!path.includes('/') && to.path == '/') {
                  next(newArray[0].path);
                } else {
                  next({
                    path: path.includes(to.path) ? to.path : '/404',
                    query: to.query,
                  });
                }
              },
            });
          }
        }
      }
    }
  }
});

export default router;
