import Home from '../../views/Home.vue';

export default {
    path: '/serviceProvider',
    name: 'serviceProvider',
    component: Home,
    redirect: '/serviceProvider/serviceProviderList',
    meta: {
        name: '服务商',
        title: '服务商管理',
        rules: 1,
    },
    children: [
        {
            path: '/serviceProvider/serviceProviderList',
            name: 'serviceProvider-serviceProviderList',
            component: () => import('@/views/serviceProvider/serviceProviderList'),
            meta: {
                name: '服务商列表',
                rules: 1,
            },
        },
        {
            path: '/serviceProvider/merchantList',
            name: 'serviceProvider-merchantList',
            component: () => import('@/views/serviceProvider/merchantList'),
            meta: {
                name: '商家列表',
                rules: 1
            },
        },
        {
            path: '/serviceProvider/merchantReview',
            name: 'serviceProvider-merchantReview',
            component: () => import('@/views/serviceProvider/merchantReview'),
            meta: {
                name: '商家审核',
                rules: 1
            },
        },
        {
            path: '/serviceProvider/withdrawalAudit',
            name: 'serviceProvider-withdrawalAudit',
            component: () => import('@/views/serviceProvider/withdrawalAudit'),
            meta: {
                name: '提现审核',
                rules: 1
            },
        },
        {
            path: '/serviceProvider/revenueOrders',
            name: 'serviceProvider-revenueOrders',
            component: () => import('@/views/serviceProvider/revenueOrders'),
            meta: {
                name: '收益订单',
                rules: 1
            },
        },
        {
            path: '/serviceProvider/rAndeDetails',
            name: 'serviceProvider-rAndeDetails',
            component: () => import('@/views/serviceProvider/rAndeDetails'),
            meta: {
                name: '收支明细',
                rules: 1
            },
        },
    ],
}