import Home from '../../views/Home.vue';
export default {
    path: '/renovation',
    name: 'renovation',
    component: Home,
    redirect: '/renovation/platformDecoration',
    meta: {
        name: '平台',
        title: '平台装修',
        rules: 1,
    },
    children: [
        {
            path: '/renovation/platformDecoration',
            name: 'renovation-platformDecoration',
            component: () => import('@/O2O/renovation/platformDecoration'),
            meta: {
                name: '平台装修',
                rules: 1,
                form: 1,
            },
        },
    ],
}