import Home from '../../views/Home.vue';
export default {
  path: '/shops',
  name: 'shops',
  component: Home,
  redirect: '/repair/shops/settlein',
  meta: {
    name: '店铺',
    title: '店铺管理',
    rules: 1,
  },
  children: [
    //都能修
    {
      path: '/repair/shops/settlein',
      name: 'repair-shops-settlein',
      component: () => import('@/repair/shops/settlein'),
      meta: {
        name: '入驻审核',
        rules: 1,
      },
    },
    {
      path: '/repair/shops/shoplist',
      name: 'repair-shops-shoplist',
      component: () => import('@/repair/shops/shoplist'),
      meta: {
        name: '商家列表',
        rules: 1,
      },
    },
    {
      path: '/repair/shops/shopmanage',
      name: 'repair-shops-shopmanage',
      component: () => import('@/repair/shops/shopmanage'),
      meta: {
        name: '店铺管理',
        rules: 1,
      },
    },
  ],
};
