<template>
    <div class="layout">
        <div class="layout-menu">
            <div class="logo">
                <el-dropdown v-if="siteList.length > 1" @command="handleCommandSite">
                    <span class="el-dropdown-link">
                        <el-image :src="shopInfo.logo || require('@/assets/user.png')"></el-image>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <div style="margin-bottom: 10px; padding-left: 20px; font-weight: bold">我的站点</div>
                        <el-dropdown-item :command="item" v-for="(item, index) in siteList" :key="index">
                            <div id="switch">
                                <el-image class="store_logo"
                                    :src="item.logo ? item.logo : require('@/assets/user.png')"></el-image>
                                <span>{{ item.name }}</span>
                                <el-image class="img" :src="require('@/assets/image/sw_icon.png')"></el-image>
                            </div>
                        </el-dropdown-item>
                        <div style="text-align: center; font-size: 12px; cursor: pointer" v-if="total_site > 10">
                            <router-link style="font-weight: 600; color: #999" to="/shop">更多站点</router-link>
                        </div>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-image v-else :src="shopInfo.logo || require('@/assets/user.png')"></el-image>
            </div>
            <el-menu class="el-menu-vertical-demo" @select="selectMenu" background-color="#010E27" text-color="#fff"
                active-text-color="#fff">
                <el-menu-item :index="index" v-for="(item, index) in routers" :key="index" :route="item.path">
                    <div :class="['item', activeIndex == index ? 'el-menu-item-active' : '']">
                        <!-- <i :class="['iconfont', item.meta.icon]"></i> -->
                        <img :src="item.meta.icon" style="margin-right: 8px" alt="" />
                        <span class="color">{{ item.meta.name }}</span>
                    </div>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="child-menu" ref="child_menu" :style="{ left: isOpen ? '120px' : '-35px' }">
            <div class="child-menu-title">
                {{ routers[activeIndex] && routers[activeIndex].meta.title }}
            </div>
            <div class="item" v-for="(item, index) in routers[activeIndex] && routers[activeIndex].children" :key="index">
                <div class="title" @click="selectChildMenu(item, index)">
                    <span :style="{
                        background: index == activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '#fff',
                    }"></span>
                    <a :style="{ color: index == activeIndex1 && !item.meta.isShowThirdMemu ? '#3370ff' : '' }">{{
                        item.meta.name }}</a>
                    <i v-show="item.meta.isShowThirdMemu" :style="item.meta.isOpenThirdMenu
                        ? {
                            transform: `rotateZ(90deg)`,
                        }
                        : {
                            transform: `rotateZ(0deg)`,
                        }
                        " class="el-icon-arrow-right"></i>
                </div>
                <div ref="item_child_box" class="item-child-box">
                    <div id="item-child" class="item-child" @click="selectThirdMemu(childItem, childIndex, index)"
                        v-for="(childItem, childIndex) in item.children" :key="childIndex">
                        <span :style="{ color: childItem.meta.selected ? '#3370ff' : '' }">{{ childItem.meta.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-content">
            <div class="seize" ref="seize" :style="{ width: isOpen ? '135px' : '0' }"></div>
            <div class="view-content">
                <div class="view-title">
                    <div v-if="!showPageName">
                        {{ shwoPageName }}
                    </div>
                    <div v-else style="margin-right: 10px">
                        <span class="SName">{{ store_type }}</span>
                        到期时间： {{ getDateformat(siteEndTime) }}
                        <span class="upRenew" @click="toUpRenew">
                            <img src="../assets/upRenew.png" alt="" />
                            {{ is_upRenew === 1 ? '套餐续费' : is_upRenew === 2 ? '套餐升级/续费' : '' }}
                        </span>
                    </div>
                    <div class="userInfo">
                        <div class="tips">
                            <!-- 消息喇叭 -->
                            <el-popover placement="bottom-end" width="485" :offset="130" trigger="click"
                                @after-enter="showNotice">
                                <template>
                                    <NoticeListTabs ref="NoticeListTabs" @removeMsg="getMsgCount"></NoticeListTabs>
                                </template>
                                <div slot="reference">
                                    <el-badge :value="msgCount" :hidden="msgCount == 0">
                                        <i class="iconfont icon--_tishi"></i>
                                    </el-badge>
                                </div>
                            </el-popover>
                        </div>
                        <el-dropdown class="userName" @command="handleCommand">
                            <span class="el-dropdown-link">
                                <i class="iconfont icon-guanliyuan"></i>
                                {{ userInfo.u_account || 'admin' }}
                                <i class="el-icon-caret-bottom"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="shop">返回站点</el-dropdown-item>
                                <el-dropdown-item command="outLogin">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div ref="router_view" class="router-view">
                    <router-view ref="rightBot" :key="$route.fullPath" @removeMsg="getMsgCount"></router-view>
                    <div class="t_logo" v-show="!is_form">
                        <el-image :src="require('@/assets/t_logo.png')"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <!-- 播放音频 -->
        <audio ref="audio" src="../assets/audio/neworderTZ.mp3"></audio>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import { path } from '@/util/config.js';
import NoticeListTabs from '@/components/noticeListTabs';
export default {
    components: {
        NoticeListTabs,
    },
    data () {
        return {
            color: 'red',
            getDateformat,
            isOpen: !1,
            activeIndex: 0,
            activeIndex1: 0,
            activeIndex2: '',
            shwoPageName: '',
            is_form: '',
            socket: null,
            socketArr: [],
            path,
            audio: null,
            end_time: null,
            store_type: '',
            showPageName: !0,
            msgCount: 0,
            showSwitch: !1,
            siteList: [],
            total_site: 0,
            is_first: 1,
            // 是否需要升级续费 1-只有续费 2-升级/续费
            is_upRenew: 1,
        };
    },
    watch: {
        $route: {
            handler (val, oldVal) {
                this.shwoPageName = val.meta.name;
                this.is_form = val.meta.form;
                this.$store.state.utils.is_update = 0;
                // 需要显示站点到期时间
                if (val.path == '/index' || val.path == '/noticeList' || val.path == '/upRenew' || val.path == '/repair/index') {
                    this.showPageName = !0;
                } else {
                    this.showPageName = !1;
                }
                // 不需要在左侧菜单显示高亮
                if (this.$route.path == '/noticeList' || this.$route.path == '/noticeDetail' || this.$route.path == '/upRenew') {
                    this.activeIndex = -1;
                    this.isOpen = !1;
                    return;
                }
                if (val.path == '/extension/couponAdd') {
                    this.activeIndex1 = 1;
                }
                // if (
                //   val.path == '/freight' ||
                //   val.path == '/customerService/addQuickReply' ||
                //   val.path == '/customerService/addQADB'
                // ) {
                //   this.activeIndex1 = -1
                // }
                this.handleMenu(oldVal);
                this.is_first = 0;
            },
            immediate: true,
        },
    },
    created () {
        this.$store.dispatch('user/getShopInfo');
        if (typeof WebSocket === 'undefined') {
            this.$message.error('您的浏览器不支持socket');
        }
        let token = this.$cookies.get('token');
        this.path += token;
        this.routers.map(item => {
            if (item.path == '/') {
                this.socket = new WebSocket(this.path);
                this.socket.onopen = this.open;
                this.socket.onmessage = this.getMessage;
                this.socket.onclose = this.close;
            }
        });
        this.getAccountList();
        this.getMsgCount();
        // 查看套餐
        this.isNeedUpOrRenew();
    },
    mounted () {
        if (this.socket) {
            this.audio = this.$refs.audio;
        }
        this.end_time = getDateformat(Number(localStorage.getItem('uniac_endtime')));
        this.store_type = localStorage.getItem('store_type');
        let a = document.createElement('div');
        a.id = 'download';
        document.body.appendChild(a);
        a.click();
        let aDom = document.getElementById('download');
        document.body.removeChild(aDom);
    },
    computed: {
        // 路由信息
        routers () {
            return this.$store.getters.menuLeft;
        },
        // 登录的用户信息
        userInfo () {
            return this.$store.getters.userInfo;
        },
        // 站点过期时间
        siteEndTime () {
            return this.$store.getters.siteEndTime;
        },
        // 用户的店铺信息
        shopInfo () {
            return this.$store.getters.shopInfo;
        },
        // 不显示的菜单
        relationMenu () {
            return this.$store.getters.relationMenu;
        },
    },
    methods: {
        // 跳转到升级续费
        toUpRenew () {
            if (this.$route.path !== '/upRenew') {
                this.$router.push('/upRenew');
            }
        },
        // 检测是否需要升级续费
        isNeedUpOrRenew () {
            this.$axios.post(this.$api.admin.upRenew.upgrade).then(res => {
                if (res.code === 0) {
                    if (res.result.upgrade_meal.length > 0) {
                        this.is_upRenew = 2;
                    }
                }
            });
        },
        //站点列表
        getAccountList () {
            this.$axios
                .post(this.$api.site.accountList, {
                    pagesize: 10,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.data;
                        if (list) {
                            list.map(i => {
                                i.is_edit = !1;
                                let now_time = parseInt(new Date().getTime() / 1000);
                                let sec = i.endtime - now_time;
                                let day = parseInt(sec / 24 / 3600);
                                if (day < 0) day = 0;
                                i.day = day;
                            });
                            this.total_site = res.result.total;
                            this.siteList = list;
                        } else {
                            this.siteList = [];
                        }
                    }
                });
        },
        //切换站点
        handleCommandSite (e) {
            e.is_site = 1;
            this.$store.dispatch('user/enterProgram', e);
        },
        handleCommand (val) {
            sessionStorage.clear();
            if (val == 'shop') {
                this.$store.dispatch('user/backProgram', { uniacid: localStorage.getItem('u_id') });
            }
            if (val == 'outLogin') {
                this.outLogin();
            }
        },
        // 开启websocket
        open () {
            console.log('开启web');
        },
        // websocket 监听消息
        getMessage (data) {
            let res = JSON.parse(data.data);
            if (res.type != undefined) {
                let path1 = this.toDealMsg(res.type);
                this.$set(res, 'path', path1);
                this.socketArr.push(res);
                this.msgCount++;
                if (this.$route.path == '/index' || this.$route.path == '/repair/index') {
                    this.$refs.rightBot.indexData.message.push({
                        content: res.message,
                        create_time: res.create_time,
                        type: res.type,
                    });
                    // this.msgCountMethod()
                }
                if (res.type == 1) {
                    this.audio.play();
                    this.msgBox(res);
                }
            }
        },
        // 关闭websocket
        close () {
            console.log('socket服务已经关闭');
        },
        getMsgCount () {
            this.$axios.post(this.$api.homeIndex.unReadCount).then(res => {
                if (res.code === 0) {
                    this.msgCount = res.result.un_read_num_message + res.result.un_read_num_notice + res.result.un_read_num_system;
                }
            });
        },

        // 筛选去处理的跳转路径
        toDealMsg (index) {
            if (index == 6) {
                this.$message({
                    message: '暂未开放客服模块',
                    type: 'warning',
                });
                return '';
            }
            let path2 = '';
            let case_id = localStorage.getItem('case_id')
            switch (index) {
                case 1:
                    path2 = case_id == 15 ? '/repair/goods/orderlist?tab_type=state_pay' : '/order/orderList?tab_type=state_pay';
                    break;
                case 2:
                    path2 = case_id == 15 ? '/repair/goods/salelist?type=1' : '/order/salesRefund';
                    break;
                case 3:
                    path2 = `/store/storeManagement?id=${index.store_id}`;
                    break;
                case 4:
                    path2 = `/store/reflectAudit?id=${index.store_id}`;
                    break;
                case 5:
                    path2 = '/set/weixin?from=1';
                    break;
                case 6:
                    path2 = '';
                    break;
                case 7:
                    path2 = case_id == 15 ? '/repair/goods/evaluate' : '/goods/GoodsEvaluate';
                    break;
                case 8:
                    path2 = '/user/userList';
                    break;
                case 9:
                    path2 = '/user/userList';
                    break;
                case 10:
                    path2 = '/user/userList';
                    break;
                default:
                    break;
            }
            return path2;
        },
        // 右侧弹出消息提示
        msgBox (res) {
            let that = this;
            const notify = this.$notify({
                duration: 3000,
                dangerouslyUseHTMLString: true,
                message: `<div class='msgBox' style='display:flex;justify-content:space-between;align-items: center;'><div><img style='width:40px;height:40px;border-radius:50%' src='${res.avatar
                    }' /></div><div style=" max-width:270px; margin:0 24px 0 20px;display: flex;flex-direction: column;justify-content: space-around;"><div style='margin-bottom:5px'><b>【${res.type == 1 ? '新订单通知' : ''
                    }】</b></div><div style='margin-left:4px;text-align:left;font-size:14px'>
        <p>用户[<span style='color:#5654F3'>${res.nickname.substring(0, 1)}***${res.nickname.substring(res.nickname.length - 1)}</span>]下了新的订单,请及时处理！</p>
        <p>订单号:${res.message.substring(14, 36)}</p>
        </div></div><div style='font-size:12px;flex-shrink: 0;text-align: center;width: 56px;height: 28px;line-height: 28px;color:#fff;background:#5654F3;cursor: pointer;' id='btnDeal' >处理<div></div>`,
            });
            // 给通知中的按钮注册事件
            notify.$el.querySelector('#btnDeal').onclick = () => {
                notify.close();
                that.$router.push({
                    path: `${res.path}`,
                });
            };
        },
        // 判断该路由是否需要显示子菜单
        isShowSonMenu () {
            let noArr = ['/index', '/upRenew', '/repair/index'];
            let res = noArr.find(item => item === this.$route.path);
            if (res) {
                this.isOpen = !1;
            } else {
                this.isOpen = !0;
            }
        },
        // 处理菜单
        handleMenu (oldMenu) {
            let that = this;
            let routers = this.routers;
            let oldIndex = this.activeIndex;
            routers.map((item, index) => {
                item.children.map((items, indexs) => {
                    //处理二级菜单选中状态
                    if (items.name == that.$route.name) {
                        that.activeIndex = index;
                        that.activeIndex1 = indexs;
                        this.isShowSonMenu();
                    }
                    if (this.relationMenu.includes(that.$route.name)) {
                        if (items.name == that.$route.meta.relation) {
                            that.activeIndex = index;
                            that.activeIndex1 = indexs;
                            this.isShowSonMenu();
                        }
                    }
                    //处理三级菜单选中状态
                    if (items.meta.isShowThirdMemu && items.children.length > 0) {
                        items.children.map((row, idx) => {
                            if (row.name == that.$route.name || (this.relationMenu.includes(that.$route.name) && row.name == that.$route.meta.relation)) {
                                that.activeIndex = index;
                                //处理浏览器回退时当前路由是否还在当前一级菜单里
                                if (oldIndex != that.activeIndex && oldMenu) {
                                    //指定浏览器回退的路由，如果有需要重置三级菜单
                                    if (['shopManagement-wechatEcology-weixin'].includes(oldMenu.name)) {
                                        that.resetThirdMenuOpen();
                                    }
                                }
                                that.activeIndex2 = idx;
                                that.activeIndex1 = indexs;
                                items.meta.isOpenThirdMenu = 1;
                                items.meta.isClickMenu = 1;
                                row.meta.selected = 1;
                                setTimeout(() => {
                                    that.$refs.item_child_box[indexs].style.height = (document.getElementById('item-child').clientHeight + 25.1) * items.children.length + 'px';
                                }, 50);
                                this.isShowSonMenu();
                            }
                        });
                    }
                });
            });

            if (document.getElementById('item-child')) {
                if (
                    routers[that.activeIndex].path != '/extension' &&
                    routers[that.activeIndex].path != '/user' &&
                    routers[that.activeIndex].path != '/shopManagement' &&
                    routers[that.activeIndex].path != '/store'
                ) {
                    that.resetThirdMenuOpen();
                } else {
                    that.resetThirdMenuSelected();
                    if (that.activeIndex2 >= 0 && that.activeIndex2 != undefined && typeof that.activeIndex2 == 'number') {
                        if (routers[that.activeIndex].children[that.activeIndex1].children) {
                            routers[that.activeIndex].children[that.activeIndex1].children[that.activeIndex2].meta.selected = 1;
                        }
                    }
                }
            }
        },
        //重置三级菜单的选中状态
        resetThirdMenuSelected () {
            this.routers.map(item => {
                item.children.map(item => {
                    if (item.children) {
                        item.children.map(item => (item.meta.selected = 0));
                    }
                });
            });
        },
        //重置三级菜单折叠状态&选中状态
        resetThirdMenuOpen () {
            if (!this.isOpen) return;
            this.$refs.item_child_box.map(item => {
                item.style.height = 0;
            });
            this.routers.map(item => {
                item.children.map(item => {
                    item.meta.isOpenThirdMenu = 0;
                });
            });
            this.resetThirdMenuSelected();
        },
        //选择三级菜单
        selectThirdMemu (data, i, y) {
            if (i != this.activeIndex2 || y != this.activeIndex1 || this.$route.meta.relation == data.name) {
                this.$router.push(data.path);
                this.resetThirdMenuSelected();
                data.meta.selected = 1;
            } else {
                if (typeof this.activeIndex2 == 'string') {
                    this.activeIndex2 = i;
                    this.$router.push(data.path);
                    this.resetThirdMenuSelected();
                    data.meta.selected = 1;
                }
            }
        },
        // 选择子菜单
        selectChildMenu (data, i) {
            let utils = this.$store.state.utils;
            if (utils.is_update) {
                this.$confirm('未保存，是否离开？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    utils.is_update = 0;
                    if (i != this.activeIndex1) {
                        this.$router.push(data.path);
                        this.$store.state.utils.is_record = !1;
                    } else {
                        if (data.path != this.$route.path && !this.$route.redirectedFrom) {
                            this.$router.push(data.path);
                        }
                    }
                    this.$refs.router_view.scrollTop = 0;
                });
            } else {
                if (i != this.activeIndex1) {
                    //是否有三级菜单需要展开
                    if (data.meta.isShowThirdMemu) {
                        if (data.meta.isOpenThirdMenu) {
                            data.meta.isOpenThirdMenu = 0;
                            this.$refs.item_child_box[i].style.height = 0;
                        } else {
                            data.meta.isOpenThirdMenu = 1;
                            this.$refs.item_child_box[i].style.height = (document.getElementById('item-child').clientHeight + 25.1) * data.children.length + 'px';
                        }
                    } else {
                        this.resetThirdMenuSelected();
                        if (data.meta.target) {
                            let routeUrl = this.$router.resolve({
                                path: data.path,
                            });
                            window.open(routeUrl.href, data.meta.target);
                        } else {
                            this.$router.push(data.path);
                        }
                        this.$store.state.utils.is_record = !1;
                    }
                } else {
                    if (data.path != this.$route.path && !this.$route.redirectedFrom) {
                        if (!data.meta.isShowThirdMemu) {
                            this.$router.push(data.path);
                        } else {
                            if (data.meta.isOpenThirdMenu) {
                                data.meta.isOpenThirdMenu = 0;
                                this.$refs.item_child_box[i].style.height = 0;
                            } else {
                                data.meta.isOpenThirdMenu = 1;
                                this.$refs.item_child_box[i].style.height = (document.getElementById('item-child').clientHeight + 25.1) * data.children.length + 'px';
                            }
                        }
                    }
                }
                this.$refs.router_view.scrollTop = 0;
            }
        },
        // 选择一级菜单
        selectMenu (i) {
            let data = this.routers[i];
            let utils = this.$store.state.utils;
            if (utils.is_update) {
                this.$confirm('未保存，是否离开？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    utils.is_update = 0;
                    if (i != this.activeIndex) {
                        this.$refs.router_view.scrollTop = 0;
                        this.$router.push(data.path);
                        this.activeIndex = i;
                        this.activeIndex1 = 0;
                        this.$store.state.utils.is_record = !1;
                        this.resetThirdMenuOpen();
                    } else {
                        if (data.path != this.$route.path) {
                            this.$refs.router_view.scrollTop = 0;
                            this.$router.push(data.path);
                            this.resetThirdMenuOpen();
                        }
                    }
                    this.isShowSonMenu();
                });
            } else {
                if (i != this.activeIndex) {
                    this.$refs.router_view.scrollTop = 0;
                    this.$router.push(data.path);
                    this.activeIndex = i;
                    this.activeIndex1 = 0;
                    this.$store.state.utils.is_record = !1;
                    this.resetThirdMenuOpen();
                    //判断二级菜单第一个是否有三级菜单，如果有就默认展开
                    if (data.children[0].meta.isShowThirdMemu) {
                        data.children[0].meta.isOpenThirdMenu = 1;
                        data.children[0].children[0].meta.selected = 1;
                        this.$nextTick(() => {
                            this.$refs.item_child_box[0].style.height = (document.getElementById('item-child').clientHeight + 25.1) * data.children[0].children.length + 'px';
                        });
                    }
                }
                this.isShowSonMenu();
            }
        },
        //退出登录
        outLogin () {
            let that = this;
            this.$store.dispatch('user/outLogin', {
                success () {
                    that.socket.close();
                },
            });
        },
        showNotice () {
            this.$nextTick(() => {
                this.$refs.NoticeListTabs.showFirst();
            });
        },
    },
};
</script>

<style lang="less" scoped>
@test: color;

.color {
    color: @test;
}

#switch {
    display: flex;
    align-items: center;

    .store_logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
    }

    span {
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.layout {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 120px;
    position: relative;

    .layout-menu {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        height: 100%;
        width: 120px;
        background: #010e27;
        color: #fff;

        .logo {
            text-align: center;
            margin: 0 auto;
            margin: 16px auto;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: relative;
            color: #2e2e2e;

            .el-image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }

        .el-menu {
            border: none;

            .el-menu-item {
                height: 50px;
                line-height: 50px;
                width: 100%;
                padding: 0 !important;
                font-size: 15px;

                .item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .iconfont {
                    color: #fff;
                    margin-right: 5px;
                    font-size: 17px;
                }
            }

            .el-menu-item-active {
                background: #1467ff;
            }
        }
    }

    .child-menu {
        position: fixed;
        width: 135px;
        height: 100%;
        top: 0;
        background: #fff;
        transition: left 0.2s;
        text-align: center;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

        .child-menu-title {
            line-height: 66px;
            text-align: center;
            font-size: 15px;
            border-bottom: 1px solid rgb(230, 224, 224);
        }

        .item {
            margin-top: 30px;

            .title {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding-left: 10px;

                span {
                    width: 3px;
                    height: 3px;
                    background: black;
                    border-radius: 5px;
                    margin-right: 3px;
                    height: 16px;
                }

                a {
                    font-size: 14px;
                    text-decoration: none;
                    position: relative;
                }

                i {
                    font-size: 14px;
                    color: #878787;
                    margin-left: 2px;
                    transition: all 0.3s;
                }
            }

            .item-child-box {
                transition: height 0.2s;
                height: 0;
                overflow: hidden;
            }

            .item-child {
                margin-top: 25px;
                font-size: 14px;
                text-align: left;
                padding-left: 25px;
                cursor: pointer;
            }
        }
    }

    .menu-content {
        width: 100%;
        background: #f8f8fa;
        height: 100%;
        display: flex;

        .seize {
            width: 0;
            height: 100%;
            transition: width 0.2s;
            flex-shrink: 0;
        }

        .view-content {
            flex: 1;
            width: 100%;
            overflow: hidden;
            flex-direction: column;
            flex-shrink: 0;
            display: flex;
            position: relative;

            .view-title {
                height: 66px;
                background: #fff;
                position: absolute;
                z-index: 100;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px 0 15px;
                font-size: 14px;
                box-shadow: 0 1px 4px rgb(216, 206, 206);

                .userInfo {
                    display: flex;

                    .iconfont {
                        font-size: 20px;
                    }

                    .tips {
                        margin-right: 25px;
                    }

                    .userName {
                        cursor: pointer;
                    }

                    .outLogin {
                        cursor: pointer;
                        margin-right: 20px;
                    }
                }

                .upRenew {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 14px;
                    background-color: #faebcf;
                    color: #754815;
                    padding: 4px 10px;
                    user-select: none;
                    cursor: pointer;
                }
            }

            .router-view {
                flex: 1;
                padding: 80px 15px 0 15px;
                overflow-y: auto;
                box-sizing: border-box;
                width: 100%;

                .t_logo {
                    text-align: center;
                    margin: 20px 0;
                }
            }
        }
    }
}

.el-notification {
    width: 440px !important;
    padding: 16px 24px !important;

    &>div {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;

        .el-notification__closeBtn {
            top: 8px !important;
            right: 8px !important;
        }
    }

    .el-notification__content {
        margin-top: 0px;
    }
}

.el-main {
    width: 100%;
}

.SName {
    display: inline-block;
    background-color: #1467ff;
    color: #fff;
    font-size: 14px;
    padding: 2px 10px;
    margin-right: 10px;
}
</style>
