<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
        <Loading v-if="loading"></Loading>
    </div>
</template>
<script>
import Loading from './components/loading.vue'

export default {
    components: {
        Loading
    },
    provide () {
        return {
            reload: this.reload
        }
    },
    data () {
        return {
            isRouterAlive: true
        }
    },
    computed: {
        loading () {
            return this.$store.state.user.loadingFlag
        }
    },
    created () {
        if (this.$cookies.get('token')) {
            this.$store.dispatch('user/getUserInfo')
        }
    },
    mounted () { },
    methods: {
        reload () {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }
    }
}
</script>

<style lang="less">
html,
body,
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background: #f8f9fa;
    width: 100%;
    height: 100%;
}

img {
    vertical-align: top;
}

.indexBox11 {
    img {
        width: 100% !important;
        height: auto !important;
    }
}

.copyParams {
    margin-left: 10px;
    font-size: 12px;
    color: #409eff;
    cursor: pointer;
}

.mzindex {
    z-index: 3000 !important;
}

.newUploadImg .el-dialog__header {
    border: none !important;
}
</style>
