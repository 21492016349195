import Home from '../../views/Home.vue';
export default {
  path: '/store',
  name: 'store',
  component: Home,
  redirect: '/store/storeList',
  meta: {
    name: '门店',
    title: '门店管理',
    rules: 1,
  },
  children: [
    {
      path: '/store/storeList',
      name: 'store-storeList',
      component: () => import('@/views/store/StoreList'),
      meta: {
        name: '门店列表',
        rules: 1,
      },
    },
    {
      path: '/store/addStore',
      name: 'store-addStore',
      component: () => import('@/views/store/AddStore'),
      meta: {
        name: '添加门店',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/store/editStore',
      name: 'store-editStore',
      component: () => import('@/views/store/AddStore'),
      hidden: true,
      meta: {
        name: '编辑门店',
        relation: 'store-storeList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/store/storeManagement',
      name: 'store-storeManagement',
      component: () => import('@/views/store/StoreManagement'),
      hidden: true,
      meta: {
        name: '门店管理',
        relation: 'store-storeList',
        rules: 0,
      },
    },
    {
      path: '/store/Storecheckin',
      name: 'store-Storecheckin',
      component: () => import('@/views/store/Storecheckin'),
      meta: {
        name: '门店入驻',
        rules: 1,
      },
    },
    {
      path: '/store/goodsExamine',
      name: 'store-goodsExamine',
      component: () => import('@/views/store/GoodsExamine'),
      meta: {
        name: '商品审核',
        rules: 1,
      },
    },
    {
      path: '/store/reflectAudit',
      name: 'store-reflectAudit',
      component: () => import('@/views/store/ReflectAudit'),
      meta: {
        name: '提现审核',
        rules: 1,
      },
    },
    {
      path: '/store/Withdrawalrecord',
      name: 'store-Withdrawalrecord',
      component: () => import('@/views/store/Withdrawalrecord'),
      meta: {
        name: '提现记录',
        relation: 'store-reflectAudit',
        rules: 0,
      },
    },
    {
      path: '/store/reflectAuditEnterprise',
      name: 'store-reflectAuditEnterprise',
      component: () => import('@/views/store/ReflectAuditEnterprise'),
      meta: {
        name: '企业提现审核',
        rules: 1,
      },
    },
    {
      path: '/store/WithdrawalrecordEnterprise',
      name: 'store-WithdrawalrecordEnterprise',
      component: () => import('@/views/store/WithdrawalrecordEnterprise'),
      meta: {
        name: '企业提现记录',
        relation: 'store-reflectAuditEnterprise',
        rules: 0,
      },
    },
    {
      path: '/goods/goodsInfo',
      component: () => import('@/views/goods/AddGoods'),
      name: 'store-goodsInfo',
      meta: {
        name: '商品详情',
        relation: 'store-goodsExamine',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/store/communityGroupPurchase',
      name: 'store-communityGroupPurchase',
      redirect: '/store/communityGroupPurchase/headAccount',
      component: () => import('@/views/store/communityGroupPurchase/index'),
      meta: {
        name: '社区团购',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/store/communityGroupPurchase/headAccount',
          name: 'store-communityGroupPurchase-headAccount',
          component: () => import('@/views/store/communityGroupPurchase/headAccount'),
          meta: {
            name: '团长账号管理',
            rules: 1,
          },
        },
        {
          path: '/store/communityGroupPurchase/headSet',
          name: 'store-communityGroupPurchase-headSet',
          component: () => import('@/views/store/communityGroupPurchase/headSet'),
          meta: {
            name: '团长设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
  ],
};
