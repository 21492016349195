import Home from '../views/Home.vue';
import Index from '../views/index/Index.vue';
/**
 * 路由注意事项
 * 路由跳转传参时不能使用地址拼接,使用query方法
 */
/**
 * 路由菜单定义参数说明
 * @param name name不能重名确保唯一性，子级路由的name定义需要和父级路由的name进行语义关联。例如：father-child
 * @param rules 是控制路由是否显示在左侧菜单上1显示0不显示
 * @param form 是控制页面在有表单的情况下是否显示底部的logo，1不显示，没值或者为0就显示
 * @param relation 是用来处理不是在左侧菜单上的路由，当从菜单点击跳转到这些个页面时，左侧菜单的选中状态，以及页面f5重新刷新后保持刷新前的样子（从那个路由跳转的参数就要定义从那个路由的name进行关联）
 * @param isOpenThirdMenu 控制三级路由菜单折叠展开 1展开0不展开
 * @param isShowThirdMemu  有三级路由菜单就定义1，没有就0或不定义
 * @param selected 三级路由菜单选中状态 1选中0不选中
 * @param target 路由跳转方式 '_blank'--打开新窗口
 */

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath);
  modules.push(value.default);
  return modules;
}, []);
let routerSurface = [
  {
    path: '/',
    name: '',
    redirect: '/index',
    component: Home,
    meta: {
      name: '首页',
      rules: 1,
    },
    children: [
      {
        path: '/index',
        component: Index,
        name: 'index',
        meta: {
          name: '首页',
          rules: 1,
          form: 1,
        },
      },
      {
        path: '/upRenew',
        component: () => import('@/views/upRenew.vue'),
        name: 'upRenew',
        meta: {
          name: '首页',
          rules: 0,
          form: 1,
          relation: 'index',
        },
      },
      //  都能修
      {
        path: '/repair/index',
        component: () => import('@/repair/index/index.vue'),
        name: 'repair-index',
        meta: {
          name: '首页',
          rules: 0,
          form: 1,
          relation: 'index',
        },
      },
    ],
  },
];
modules.map(item => routerSurface.push(item));
export default routerSurface;
