import Home from '../../views/Home.vue';
export default {
  path: '/shopManagement',
  name: 'shopManagement',
  component: Home,
  redirect: '/shopManagement/shopDecoration',
  meta: {
    name: '店铺',
    title: '店铺管理',
    rules: 1,
  },
  children: [
    {
      path: '/shopManagement/shopOverview',
      name: 'shopManagement-shopOverview',
      component: () => import('@/views/shopManagement/shopOverview.vue'),
      meta: {
        name: '店铺总览',
        rules: 1,
      },
    },
    {
      path: '/e-sports/platformDecoration',
      name: 'sports-platformDecoration',
      component: () => import('@/sports/renovation/platformDecoration.vue'),
      meta: {
        name: '平台装修',
        form: 1,
        rules: 1,
      },
    },
    {
      path: '/renovation/platformDecoration',
      name: 'renovation-platformDecoration',
      component: () => import('@/O2O/renovation/platformDecoration'),
      meta: {
        name: '平台装修',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shopManagement/shopDecoration',
      name: 'shopManagement-shopDecoration',
      component: () => import('@/views/shopManagement/shopDecoration/index.vue'),
      meta: {
        name: '店铺装修',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/bottomMenu',
      name: 'shopManagement-bottomMenu',
      meta: {
        name: '底部菜单',
        rules: 1,
        form: 1,
      },
      component: () => import('@/views/addPages/pages/bottomMenu.vue'),
    },
    {
      path: '/shopManagement/colorStyle',
      name: 'shopManagement-colorStyle',
      meta: {
        name: '配色风格',
        rules: 1,
        form: 1,
      },
      component: () => import('@/views/addPages/pages/colorStyle.vue'),
    },

    {
      path: '/shopManagement/wechatEcology',
      name: 'shopManagement-wechatEcology',
      redirect: '/shopManagement/wechatEcology/weixin',
      component: () => import('@/views/shopManagement/wechatEcology/index'),
      meta: {
        name: '微信生态',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/shopManagement/wechatEcology/weixin',
          name: 'shopManagement-wechatEcology-weixin',
          component: () => import('@/views/shopManagement/Weixin'),
          meta: {
            name: '微信小程序',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/officialAccountSet',
          name: 'shopManagement-wechatEcology-officialAccountSet',
          component: () => import('@/views/shopManagement/wechatEcology/officialAccountSet'),
          meta: {
            name: '微信公众号',
            rules: 1,
          },
        },
        {
          path: '/shopManagement/wechatEcology/thirdSet',
          name: 'shopManagement-wechatEcology-thirdSet',
          component: () => import('@/views/shopManagement/wechatEcology/thirdSet'),
          meta: {
            name: '第三方配置',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/shopManagement/wxsubscribe',
          name: 'shopManagement-wechatEcology-weixin',
          component: () => import('@/views/shopManagement/sonPage/wxSon/subscribe.vue'),
          meta: {
            name: '订阅消息',
            relation: 'shopManagement-weixin',
            rules: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/videoNumber',
          name: 'shopManagement-wechatEcology-videoNumber',
          component: () => import('@/views/shopManagement/wechatEcology/videoNumber'),
          meta: {
            name: '视频号',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/videoNumberCategory',
          name: 'shopManagement-wechatEcology-videoNumberCategory',
          component: () => import('@/views/shopManagement/wechatEcology/videoNumberCategory'),
          meta: {
            name: '视频号类目',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/sharer',
          name: 'shopManagement-wechatEcology-sharer',
          component: () => import('@/views/shopManagement/wechatEcology/sharer'),
          meta: {
            name: '视频号分享员',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/videoNumberGoods',
          name: 'shopManagement-wechatEcology-videoNumberGoods',
          component: () => import('@/views/shopManagement/wechatEcology/videoNumberGoods'),
          meta: {
            name: '视频号商品',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/shopManagement/wechatEcology/shareToGroup',
          name: 'shopManagement-wechatEcology-shareToGroup',
          component: () => import('@/views/shopManagement/wechatEcology/shareToGroup'),
          meta: {
            name: '分享到群',
            rules: 1,
            selected: 0,
            form: 1,
          },
        },
        {
          path: '/shopManagement/wechatEcology/wxsubscribe',
          name: 'shopManagement-wechatEcology-wxsubscribe',
          component: () => import('@/views/shopManagement/wechatEcology/wxsubscribe'),
          meta: {
            name: '公众号',
            rules: 1,
            selected: 0,
          },
        },
      ],
    },
    {
      path: '/shopManagement/alipay',
      name: 'shopManagement-alipay',
      component: () => import('@/views/shopManagement/Alipay'),
      meta: {
        name: '支付宝小程序',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/quick',
      name: 'shopManagement-quick',
      component: () => import('@/views/shopManagement/Quick'),
      meta: {
        name: '快应用配置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shopManagement/byterun',
      name: 'shopManagement-byterun',
      component: () => import('@/views/shopManagement/byterun.vue'),
      meta: {
        name: '抖音小程序',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/Baidu',
      name: 'shopManagement-Baidu',
      component: () => import('@/views/shopManagement/Baidu.vue'),
      meta: {
        name: '百度小程序',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/QQ',
      name: 'shopManagement-QQ',
      component: () => import('@/views/shopManagement/QQ.vue'),
      meta: {
        name: 'QQ小程序',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/Gaode',
      name: 'Gaode',
      component: () => import('@/views/shopManagement/Gaode.vue'),
      meta: {
        name: 'shopManagement-Gaode',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/HuaweiService',
      name: 'shopManagement-HuaweiService',
      component: () => import('@/views/shopManagement/HuaweiService.vue'),
      meta: {
        name: '华为服务号',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/platformSettings',
      name: 'shopManagement-PlatformSettings',
      component: () => import('@/views/shopManagement/PlatformSettings.vue'),
      meta: {
        name: '平台设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shopManagement/foodDecorationSingle',
      name: 'shopManagement-FoodDecorationSingle',
      redirect: '/foodSingle',
      meta: {
        name: '餐饮装修单店',
        rules: 1,
        target: '_blank',
      },
    },
    {
      path: '/shopManagement/foodDecorationMulti',
      name: 'shopManagement-FoodDecorationMulti',
      redirect: '/foodMulti',
      meta: {
        name: '餐饮装修多店',
        rules: 1,
        target: '_blank',
      },
    },
    {
      path: '/shopManagement/retailDecorationSingle',
      name: 'shopManagement-RetailDecorationSingle',
      redirect: '/retailSingle',
      meta: {
        name: '零售装修单店',
        rules: 1,
        target: '_blank',
      },
    },
    {
      path: '/shopManagement/retailDecorationMulti',
      name: 'shopManagement-retailDecorationMulti',
      component: () => import('@/views/multiVersion/retail/multiindex.vue'),
      meta: {
        name: '零售装修多店',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shopManagement/indexset',
      name: 'shopManagement-indexset',
      component: () => import('@/gateway/shop/indexset'),
      meta: {
        name: '首页设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shopManagement/gatewayShopList',
      name: 'shopManagement-gatewayShopList',
      component: () => import('@/gateway/shop/list'),
      meta: {
        name: '店铺列表',
        rules: 1,
      },
    },
    {
      path: '/shopManagement/gatewayGoodsManagement',
      name: 'shopManagement-gatewayGoodsManagement',
      component: () => import('@/gateway/shop/goodsManagement'),
      meta: {
        name: '商品管理',
        rules: 0,
        relation: 'shopManagement-gatewayShopList',
      },
    },
    {
      path: '/shopManagement/gatewayServiceManagement',
      name: 'shopManagement-gatewayServiceManagement',
      component: () => import('@/gateway/shop/serviceManagement'),
      meta: {
        name: '服务管理',
        rules: 0,
        relation: 'shopManagement-gatewayShopList',
      },
    },
    {
      path: '/shopManagement/gatewayProxy',
      name: 'shopManagement-gatewayProxy',
      component: () => import('@/gateway/shop/gatewayProxy'),
      meta: {
        name: '代销合同',
        rules: 0,
        relation: 'shopManagement-gatewayShopList',
      },
    },
    //都能修
    {
      path: '/repair/shopManagement/platformset',
      name: 'repair-shopManagement-platformset',
      component: () => import('@/repair/shop/platformset/layout'),
      meta: {
        name: '平台设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/repair/shopManagement/recruitset',
      name: 'repair-shopManagement-recruitset',
      component: () => import('@/repair/shop/recruitset'),
      meta: {
        name: '招募设置',
        rules: 1,
        form: 1,
      },
    },
  ],
};
