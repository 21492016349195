import Home from '../../views/Home.vue';
export default {
  path: '/service',
  name: 'service',
  component: Home,
  redirect: '/service/list',
  meta: {
    name: '服务',
    title: '服务管理',
    rules: 1,
  },
  children: [
    {
      path: '/service/list',
      name: 'service-list',
      component: () => import('@/views/service/List'),
      meta: {
        name: '服务列表',
        rules: 1,
      },
    },
    {
      path: '/service/addService',
      name: 'service-addService',
      component: () => import('@/views/service/AddService'),
      meta: {
        name: '添加服务',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/service/serviceInfo',
      name: 'service-serviceInfo',
      component: () => import('@/views/service/AddService'),
      meta: {
        name: '服务详情',
        relation: 'service-list',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/service/type',
      name: 'service-type',
      component: () => import('@/views/service/Type'),
      meta: {
        name: '服务分类',
        rules: 1,
      },
    },
    {
      path: '/service/order',
      name: 'service-order',
      component: () => import('@/views/service/Order'),
      meta: {
        name: '服务订单',
        rules: 1,
      },
    },
    {
      path: '/service/orderInfo',
      name: 'service-orderInfo',
      component: () => import('@/views/service/OrderInfo'),
      meta: {
        name: '订单信息',
        relation: 'service-order',
        rules: 0,
      },
    },
    {
      path: '/service/afterSale',
      name: 'service-afterSale',
      component: () => import('@/views/service/AfterSale'),
      meta: {
        name: '服务售后',
        rules: 1,
      },
    },
    {
      path: '/service/afterSaleInfo',
      name: 'service-afterSaleInfo',
      component: () => import('@/views/service/AfterSaleInfo'),
      meta: {
        name: '售后信息',
        relation: 'service-afterSale',
        rules: 0,
      },
    },
    {
      path: '/service/evaluate',
      name: 'service-evaluate',
      component: () => import('@/views/service/Evaluate'),
      meta: {
        name: '服务评价',
        rules: 1,
      },
    },
    {
      path: '/service/beautyServiceList',
      name: 'service-beautyServiceList',
      component: () => import('@/beauty/service/serviceList'),
      meta: {
        name: '服务列表',
        rules: 1,
      },
    },
    {
      path: '/service/beautyAddService',
      name: 'service-beautyAddService',
      component: () => import('@/beauty/service/addService'),
      meta: {
        name: '添加服务',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/service/beautyEditService',
      name: 'service-beautyEditService',
      component: () => import('@/beauty/service/addService'),
      meta: {
        name: '编辑服务',
        rules: 0,
        form: 1,
        relation: 'service-beautyAddService',
      },
    },
    {
      path: '/service/beautyCardService',
      name: 'service-beautyCardService',
      component: () => import('@/beauty/service/cardService'),
      meta: {
        name: '卡片服务',
        rules: 1,
      },
    },
    {
      path: '/service/beautyAddCardService',
      name: 'service-beautyAddCardService',
      component: () => import('@/beauty/service/addCardService'),
      meta: {
        name: '添加卡片服务',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/service/beautyCardServiceInfo',
      name: 'service-beautyCardServiceInfo',
      component: () => import('@/beauty/service/addCardService'),
      meta: {
        name: '查看卡片服务',
        rules: 0,
        form: 1,
        relation: 'service-beautyAddCardService',
      },
    },
    //gateway
    {
      path: '/gateway/service/serviceType',
      name: 'gateway-service-serviceType',
      component: () => import('@/gateway/service/serviceType'),
      meta: {
        name: '服务分类',
        rules: 1,
      },
    },
    {
      path: '/gateway/service/serviceList',
      name: 'gateway-service-serviceList',
      component: () => import('@/gateway/service/serviceList'),
      meta: {
        name: '服务列表',
        rules: 1,
      },
    },
    {
      path: '/gateway/service/serviceAdd',
      name: 'gateway-service-serviceAdd',
      component: () => import('@/gateway/service/serviceAdd'),
      meta: {
        name: '添加服务',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/gateway/service/serviceEdit',
      name: 'gateway-service-serviceEdit',
      component: () => import('@/gateway/service/serviceAdd'),
      meta: {
        name: '编辑服务',
        rules: 0,
        form: 1,
        relation: 'gateway-service-serviceList',
      },
    },
    {
      path: '/gateway/service/serviceEvaluate',
      name: 'gateway-service-serviceEvaluate',
      component: () => import('@/gateway/service/serviceEvaluate'),
      meta: {
        name: '服务评价',
        rules: 1,
      },
    },
    // 都能修
    {
      path: '/repair/service/list',
      name: 'repair-service-list',
      component: () => import('@/repair/service/list'),
      meta: {
        name: '服务列表',
        rules: 1,
      },
    },
    {
      path: '/repair/service/add',
      name: 'repair-service-add',
      component: () => import('@/repair/service/form'),
      meta: {
        name: '添加服务',
        rules: 1,
      },
    },
    {
      path: '/repair/service/edit',
      name: 'repair-service-edit',
      component: () => import('@/repair/service/form'),
      meta: {
        name: '编辑服务',
        relation: 'repair-service-list',
        rules: 0,
      },
    },
    {
      path: '/repair/service/typelist',
      name: 'repair-service-typelist',
      component: () => import('@/repair/service/typelist'),
      meta: {
        name: '服务分类',
        rules: 1,
      },
    },
    {
      path: '/repair/service/addtype',
      name: 'repair-service-addtype',
      component: () => import('@/repair/service/typeform'),
      meta: {
        name: '添加分类',
        rules: 1,
      },
    },
    {
      path: '/repair/service/edittype',
      name: 'repair-service-edittype',
      component: () => import('@/repair/service/typeform'),
      meta: {
        name: '编辑分类',
        relation: 'repair-service-typelist',
        rules: 0,
      },
    },
    {
      path: '/service/repairOrder',
      name: 'service-repairOrder',
      component: () => import('@/repair/service/order'),
      meta: {
        name: '服务订单',
        rules: 1,
      },
    },
    {
      path: '/service/repairOrderInfo',
      name: 'service-repairOrderInfo',
      component: () => import('@/repair/service/orderInfo'),
      meta: {
        name: '订单信息',
        relation: 'service-repairOrder',
        rules: 0,
      },
    },
    {
      path: '/service/repairAfterSale',
      name: 'service-repairAfterSale',
      component: () => import('@/repair/service/afterSale'),
      meta: {
        name: '服务售后',
        rules: 1,
      },
    },
    {
      path: '/service/repairAfterSaleInfo',
      name: 'service-repairAfterSaleInfo',
      component: () => import('@/repair/service/afterSaleInfo'),
      meta: {
        name: '售后详情',
        relation: 'service-repairAfterSale',
        rules: 0,
      },
    },
    {
      path: '/service/repairEvaluate',
      name: 'service-repairEvaluate',
      component: () => import('@/repair/service/evaluate'),
      meta: {
        name: '服务评价',
        rules: 1,
      },
    },
  ],
};
