import Home from '../../views/Home.vue';
export default {
    path: '/article',
    name: 'article',
    component: Home,
    redirect: '/article/articleList',
    meta: {
        name: '资讯',
        title: '资讯管理',
        rules: 1,
    },
    children: [
        {
            path: '/article/articleList',
            name: 'article-articleList',
            component: () => import('@/views/article/ArticleList'),
            meta: {
                name: '文章列表',
                rules: 1,
            },
        },
        {
            path: '/article/articleAdd',
            name: 'article-articleAdd',
            component: () => import('@/views/article/ArticleAdd'),
            meta: {
                name: '添加文章',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/article/articleEdit',
            name: 'article-articleEdit',
            component: () => import('@/views/article/ArticleAdd'),
            meta: {
                name: '编辑文章',
                relation: 'article-articleList',
                rules: 0,
                form: 1,
            },
        },
        {
            path: '/article/articleType',
            name: 'article-articleType',
            component: () => import('@/views/article/ArticleType'),
            meta: {
                name: '文章分类',
                rules: 1,
            },
        },
    ],
}