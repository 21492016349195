import store from '../store';
const modulesFiles = require.context('../store/modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  modules.push(moduleName);
  return modules;
}, []);
/**
 * 转数字
 */
export function turnNum(val) {
  return val ? 1 : 0;
}
/**
 * 转布尔值
 */
export function turnBle(val) {
  return Number(val) ? !0 : !1;
}

/**
 *
 * @param name 要清除store模块的名称
 */
export function resetVuex(name) {
  let moduleName = name || modules;
  moduleName.map(item => store.commit(`${item}/resetState`));
}

export function matchDataAssign(val, data) {
  for (let i in data) {
    for (let y in val) {
      if (y == i) val[y] = data[i];
    }
  }
  return val;
}

export function getfilesize(size) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return size + 'B';
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'K'; //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'M'; //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'G'; //G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T'; //T
}

// 截取视频第一帧 url传base64位
export function getVideoBase64(url) {
  return new Promise(function (resolve, reject) {
    let dataURL = '';
    let video = document.createElement('video');
    video.setAttribute('src', url);
    video.setAttribute('width', 400);
    video.setAttribute('height', 240);
    video.setAttribute('preload', 'auto');
    video.addEventListener('loadeddata', function () {
      let canvas = document.createElement('canvas'),
        width = video.width, //canvas的尺寸和图片一样
        height = video.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(video, 0, 0, width, height); //绘制canvas
      dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
      resolve(dataURL);
    });
  });
}
// 将视频转成base64进制的
export function changeVideoBase64(file, callback = () => {}) {
  var reader = new FileReader();
  var rs = reader.readAsDataURL(file);
  reader.onload = e => {
    var videoSrc = e.target.result;
    callback(videoSrc);
  };
}

export function getSourceWidthHeight(url, callback = () => {}) {
  let img = new Image();
  img.src = url;
  return new Promise(resolve => {
    img.onload = function () {
      resolve(img);
    };
  });
}

export function getSelected() {
  if (window.getSelection) {
    return window.getSelection().toString();
  } else if (document.getSelection) {
    return document.getSelection().toString();
  } else {
    var selection = document.selection && document.selection.createRange();
    if (selection.text) {
      return selection.text.toString();
    }
    return '';
  }
}

// 验证附件的数据合法性
export function checkAttachments(arr) {
  let attachmentsFlag = true;
  // 验证附件参数是否合法
  let errorTxt = null;
  let errIndex = null;
  for (let i = 0; i < arr.length; i++) {
    errIndex = i;
    let item = arr[i];
    if (item.msgtype === 'image') {
      if (!item.image.media_id) {
        errorTxt = '图片参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
      if (!item.image.pic_url) {
        errorTxt = '请上传图片';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'video') {
      if (!item.video.media_id) {
        errorTxt = '视频参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
      if (!item.video.path) {
        errorTxt = '请上传视频';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'link') {
      if (!item.link.title) {
        errorTxt = '请填写图文标题';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.url) {
        errorTxt = '请填写图文链接';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.desc) {
        errorTxt = '请填写图文秒速';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.picurl) {
        errorTxt = '请填写图文封面';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'miniprogram') {
      if (!item.miniprogram.appid) {
        errorTxt = '请填写小程序APPID';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.page) {
        errorTxt = '请填写小程序page路径';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.title) {
        errorTxt = '请填写小程序标题';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.pic_media_id) {
        errorTxt = '请填写小程序消息封面';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'file') {
      if (!item.file.media_id) {
        errorTxt = '文件参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
    }
  }
  return { attachmentsFlag, errorTxt, errIndex };
}
