import Home from '../../views/Home.vue';
export default {
    path: '/member',
    name: 'member',
    component: Home,
    redirect: '/member/partnersEquity',
    meta: {
        name: '会员',
        title: '会员管理',
        rules: 1,
    },
    children: [
        {
            path: '/member/partnersEquity',
            name: 'member-partnersEquity',
            component: () => import('@/gateway/member/partnersEquity'),
            meta: {
                name: '合伙人权益',
                rules: 1,
            },
        },
        {
            path: '/member/partnersEquityInfo',
            name: 'member-partnersEquityInfo',
            component: () => import('@/gateway/member/partnersEquityInfo'),
            meta: {
                name: '合伙人权益',
                rules: 0,
                form:1,
                relation: 'member-partnersEquity',
            },
        },
        {
            path: '/member/discountSet',
            name: 'member-discountSet',
            component: () => import('@/gateway/member/discountSet'),
            meta: {
                name: '会员折扣',
                rules: 1,
                form:1,
            },
        },
        {
            path: '/member/list',
            name: 'member-list',
            component: () => import('@/gateway/member/list'),
            meta: {
                name: '会员列表',
                rules: 1,
            },
        },
        {
            path: '/member/info',
            name: 'member-info',
            component: () => import('@/gateway/member/info'),
            meta: {
                name: '会员详情',
                rules: 0,
                relation:'member-list'
            },
        },
        {
            path: '/member/apply',
            name: 'member-apply',
            component: () => import('@/gateway/member/apply'),
            meta: {
                name: '会员申请',
                rules: 1,
            },
        },
        {
            path: '/member/set',
            name: 'member-set',
            component: () => import('@/gateway/member/set'),
            meta: {
                name: '会员设置',
                rules: 1,
            },
        },
        {
            path: '/member/commissionSet',
            name: 'member-commissionSet',
            component: () => import('@/gateway/member/commissionSet'),
            meta: {
                name: '佣金设置',
                rules: 1,
                form:1
            },
        },
        {
            path: '/member/explain',
            name: 'member-explain',
            component: () => import('@/gateway/member/explain'),
            meta: {
                name: '会员说明',
                rules: 1,
                form:1
            },
        },
        {
            path: '/member/partnerOrder',
            name: 'member-partnerOrder',
            component: () => import('@/gateway/member/partnerOrder'),
            meta: {
                name: '合伙人订单',
                rules: 1,
            },
        },
        // 都能修
        {
            path: '/member/repairPartnerOrder',
            name: 'member-repairPartnerOrder',
            component: () => import('@/repair/member/partnerOrder'),
            meta: {
                name: '保证金订单',
                rules: 1,
            },
        }
    ],
}
