import axios from '../../util/axios'
import api from '../../util/api'
import { Message } from 'element-ui'
const default_state = {
  page: 1,
  rows: 10,
  is_record: !1,
  is_update: 0,
  goodsRankList: [],
  member_discount: 0
}
const state = {
  page: 1,
  rows: 10,
  is_record: !1,
  is_update: 0,
  goodsRankList: [],
  member_discount: 0
}
const mutations = {
  setAnkingList(state, data) {
    state.goodsRankList = data
  },
  resetState(state) {
    let data = Object.assign({}, default_state)
    for (let i in data) {
      state[i] = data[i]
    }
  }
}
const actions = {
  //获取商品榜单
  getAnkingList({ commit }, data = {}) {
    axios.post(api.data.ankingList, data).then((res) => {
      if (res.code == 0) {
        let info = res.result
        info.visitor.map((item) => {
          item.num = item.visitor_count
          item.num_yesterday = Math.floor(item.num_yesterday * 100) / 100
          item.num_last_week = Math.floor(item.num_last_week * 100) / 100
        })
        info.sales.map((item) => {
          item.num = item.sales
          item.num_yesterday = Math.floor(item.num_yesterday * 100) / 100
          item.num_last_week = Math.floor(item.num_last_week * 100) / 100
        })
        info.amount.map((item) => {
          item.num = Math.floor(item.pay_amount * 100) / 100
          item.num_yesterday = Math.floor(item.num_yesterday * 100) / 100
          item.num_last_week = Math.floor(item.num_last_week * 100) / 100
        })
        let arr = [
          {
            title: '访客榜',
            alias: '访客数',
            list: info.visitor
          },
          {
            title: '销量榜',
            alias: '支付件数',
            list: info.sales
          },
          {
            title: '支付金额榜',
            alias: '支付金额（元）',
            list: info.amount
          }
        ]
        commit('setAnkingList', arr)
      } else {
        Message.error(res.msg)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
