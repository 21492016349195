import Home from '../../views/Home.vue';
export default {
    path: '/data',
    name: 'data',
    component: Home,
    redirect: '/data/dataSurvey',
    meta: {
        name: '数据',
        title: '数据管理',
        rules: 1,
    },
    children: [
        {
            path: '/data/dataSurvey',
            name: 'data-dataSurvey',
            component: () => import('@/views/data/DataSurvey'),
            meta: {
                name: '数据概况',
                rules: 1,
            },
        },
        {
            path: '/data/realAnalysis',
            name: 'data-realAnalysis',
            component: () => import('@/views/data/RealAnalysis'),
            meta: {
                name: '实时分析',
                rules: 1,
            },
        },
        {
            path: '/data/dataAnalysis',
            name: 'data-dataAnalysis',
            component: () => import('@/views/data/DataAnalysis'),
            meta: {
                name: '交易分析',
                rules: 1,
            },
        },
        {
            path: '/data/goodsAnalysis',
            name: 'data-goodsAnalysis',
            component: () => import('@/views/data/GoodsAnalysis'),
            meta: {
                name: '商品分析',
                rules: 1,
            },
        },
    ],
}