import Home from '../../views/Home.vue';
export default {
    path: '/basics',
    name: 'basics',
    component: Home,
    redirect: '/basics/basicsSet',
    meta: {
        name: '基础',
        title: '基础设置',
        rules: 1,
    },
    children: [
        {
            path: '/basics/basicsSet',
            name: 'basics-basicsSet',
            component: () => import('@/O2O/basics/basicsSet'),
            meta: {
                name: '基础设置',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/basics/serviceAgreement',
            name: 'basics-serviceAgreement',
            component: () => import('@/O2O/basics/serviceAgreement'),
            meta: {
                name: '服务协议',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/set/sourceCenter',
            name: 'set-sourceCenter',
            meta: {
                name: '素材中心',
                rules: 1,
                form: 1,
            },
            component: () => import('@/views/set/sourceCenter/index00.vue'),
        },
    ],
}